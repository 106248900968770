/* eslint-disable no-useless-escape */
import { uploadImg } from "../../common/interface/common";
import { InsertAtomic } from "../atomic/AtomicFunction";
import getYoutubeTitle from 'get-youtube-title';

export const pastedFiles = (file, editor) => {//기존 base64 -> 이미지 업로드 처리
    // var reader = new FileReader();
    // console.log(file[0])
    // reader.readAsDataURL(file[0]);
    // reader.onloadend = function () {
    //     var base64data = reader.result;
    //     InsertAtomic(editor, 'image', 'IMMUTABLE', { src: base64data })
    // }
        
    const promise1 = new Promise(function(resolve,reject){
        if(file[0]){
            uploadImg(file[0]).then((response) => {
                if (response && response.status === 200 && response.data.image_urls[0]){
                    resolve(response.data.image_urls[0]);
                    window.parent.postMessage({ name: 'editor', action: 'overlayLoading' ,isLoading: false }, '*');
                }
            }).catch(err => {
                console.log("error");
                window.parent.postMessage({ name: 'editor', action: 'overlayLoading' ,isLoading: false }, '*');
            });
        }
        else{
            resolve("");
            window.parent.postMessage({ name: 'editor', action: 'overlayLoading' ,isLoading: false }, '*');
        }
    });
    Promise.all([promise1]).then(function(values){
        InsertAtomic(editor, 'image', 'IMMUTABLE', { src: values[0] });
    });
}

export const pastedText = (text, editor) => {
    const regexImg = /^(?=(https?:\/\/)([^\/]*)(\.)(.*))((.*imgurl.*)|(.*img.*)|(.*jpg.*)|(.*jpeg.*)|(.*png.*)|(.*gif.*))/;
    const regexYoutube = /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)\&?/;
    const regexVideo = /^(?=(https?:\/\/)([^\/]*)(\.)(.*))((.*mp4.*))/;
    const regexUrl = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
    
    if (regexImg.exec(text)) {
        InsertAtomic(editor, 'image', 'IMMUTABLE', { src: text })
        return true;
    } 
    else if (regexYoutube.exec(text)) {
        let youtubeId = null;
        if (text.indexOf('watch?v=') > -1) {
            youtubeId = text.substring(text.indexOf('watch?v=') + 8, text.indexOf("&") > -1 ? text.indexOf("&") : text.length);
        } 
        else {
            if (text.indexOf('embed/') > -1) {
                youtubeId = text.substring(text.indexOf('embed/') + 6, text.indexOf('?') > -1 ? text.indexOf('?') : text.length);
            }
            else if(text.indexOf('youtu.be') > -1) {
                youtubeId = text.substring(text.indexOf('youtu.be/') + 9, text.length);
            }
        }
        getYoutubeTitle(youtubeId, function (err, title) {
            InsertAtomic(editor, 'youtube', 'IMMUTABLE', { src: youtubeId, title: title});
        });
        return true;
    } 
    else if(regexVideo.exec(text)){
        InsertAtomic(editor, 'video', 'IMMUTABLE', { src: text })
        return true;
    }
}

export const droppedFiles = (file, editor) => {
    var reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onloadend = function () {
        var base64data = reader.result;
        InsertAtomic(editor, 'image', 'IMMUTABLE', { src: base64data })
    }
}