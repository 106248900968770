import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Editor as DraftEditor, EditorState, getDefaultKeyBinding, KeyBindingUtil, Modifier, RichUtils, SelectionState } from 'draft-js'
import { getSelectedBlock, insertNewUnstyledBlock, clearEditorContent, getAllBlocks, getSelectedBlocksList } from 'draftjs-utils'
import { blockRenderer, extendedBlockRenderMap, blockStyleFn } from '../func/blockRenderer';
import { pastedFiles, pastedText, droppedFiles } from '../func/handler';
import { FontFamily, FontSize, LineHeight, ListTypeStyle, Subscript } from '../styles/FontStyle'
import { makeStyles } from '@material-ui/core';
import { InsertAtomic } from '../atomic/AtomicFunction';
import Lottie from 'react-lottie';
import Loading from '../../common/loading/loading.json';
import moveSelectionBackward from 'draft-js/lib/moveSelectionBackward';
import moveSelectionForward from 'draft-js/lib/moveSelectionForward';
import { deleteEditorSelection, getEditorBlock, setCurrentFontStyle } from '../../common/custom/editorFunction';
import { isAndroid, isAppleWeb, isPC, isAppleApp } from '../../common/interface/common';
var Keys = require("fbjs/lib/Keys");

const lottieOptions = {
    animationData: Loading,   
    loop: true,        
    autoplay: true,   
    rendererSettings: {
      className: 'add-class', // svg에 적용
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
const useStyles = makeStyles(theme => ({
    main: {
        height: 'calc(100vh - 69px)', 
        padding: '0px 16px', 
        paddingBottom: '10px',
        paddingTop:'10px',
        background: '#ffffff', 
        overflow: 'auto',
        '&::-webkit-scrollbar-button': {
            width: '0px',
            height: '0px'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#CBCBCB',
            border: '3px solid transparent',
            borderRadius: '10px',
            backgroundClip: 'content-box'
        },
        '&::-webkit-scrollbar': {
            width: '20px',
        },
    }
}));

const Editor = ({ editor }) => {
    const classes = useStyles();
    const [customStyleMap, setCustomStyleMap] = useState();
    const [height, setHeight] = useState(0);
    const editorComp = useRef();
    const editFrame = useRef(null);

    // localstorage
    useEffect(() => {
        localStorage.removeItem('mentionList');//멘션목록
        localStorage.removeItem('inline-style');//인라인 스타일
        localStorage.removeItem('add-inline-style');//토글 추가한 인라인 스타일
        localStorage.removeItem('del-inline-style');//토글 삭제한 인락인 스타일
        localStorage.removeItem('key-inline-style');//인라인 focus key 
        localStorage.removeItem('idx-inline-style');//인라인 focus offset
        localStorage.removeItem('input-length-composition')//컴포지션 자동완성 글자수
        localStorage.removeItem('is-autocomplete')//자동완성 자판인지 체크
        //개발용
        const data = localStorage.getItem('CONTENT_TEST');
        if(data != null){
            const getSavedEditorData = () => {
                return data ? JSON.parse(data) : null;
            }
            const contentState = getSavedEditorData();
            if(contentState.raw != null ){
                window.postMessage({ type: 'SET', param: { type: 'data', data: contentState.raw } }, '*');
            }
            else{
                window.postMessage({ type: 'SET', param: { type: 'data', data: contentState } }, '*');
            }
        }
        if (isPC() || isAndroid()) {
            
        }
        else if (isAppleWeb() || isAppleApp()) {
            ohiStart('En');
        }
        window.parent.postMessage({ name: 'editor', action: 'ready', status: true}, '*');
        // window.parent.postMessage({ name: 'postHeight', height: height}, '*');

        window.addEventListener('message', (e) => {
            if(e.data.name === 'scrollDown'){
                // editFrame.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                // editFrame.current.scroll({top: e.data.offsetTop, left: 0, behavior: 'smooth'})
                editFrame.current.scroll({top: e.data.offsetTop, left: 0})
            }
        });
    }, []);
    
    useLayoutEffect(() => {
        if(editor.readOnly){
            const resizePage = (time) => {
                setTimeout(() => {
                    if(editFrame != null){
                        setHeight(editFrame.current.children[0].clientHeight+10);
                    }
                }, time);
            }
            resizePage(100);
            resizePage(200);
            resizePage(300);
            resizePage(400);
            resizePage(500);
            resizePage(1000);
            resizePage(2000);
            resizePage(3000);
        }
    });

    useLayoutEffect(() => {
        window.parent.postMessage({ name: 'postHeight', height: height}, '*');
    }, [height]);

    useEffect(()=>{
        const fontColor = JSON.parse(JSON.stringify(editor.fontColor))
        const backgroundColor = JSON.parse(JSON.stringify(editor.backgroundColor))
        const temp = { ...FontFamily, ...FontSize, ...Subscript, ...LineHeight, ...fontColor, ...backgroundColor, ...ListTypeStyle }
        setCustomStyleMap(temp)
    }, [editor.fontColor, editor.backgroundColor]);
    
    const handlePastedFiles = (file) => {
        pastedFiles(file, editor)
    }

    const handlePastedText = (text) => {
        return pastedText(text, editor);
    }

    const handleDroppedFiles = (selection, file) => {
        droppedFiles(file, editor)
    }


    function keyBindingFn(e) {
        const { hasCommandModifier } = KeyBindingUtil;
        const selection1 = editor.editorState.getSelection();
        if(selection1.anchorKey !== selection1.focusKey){//여러줄 드래그//리포스트 삭제하려할때 예외처리
            let contentState = editor.editorState.getCurrentContent();
            const blocks = getSelectedBlocksList(editor.editorState);
            let flag = false;
            blocks._tail.array.map(ev=>{
                if(ev.getEntityAt(0) !== null){
                    const type = contentState.getEntity(ev.getEntityAt(0)).getType();
                    if(type === 'repost'){
                        flag = true;
                    }
                }
            });
            if(flag){
                window.parent.postMessage({ name: 'editor', action: 'alert', text: '리포스트 영역은 삭제할 수 없습니다.' }, '*');
                const doc = document.activeElement;//자동완성을 풀어줘야함
                doc.blur();
                return false;
            }
        }
        else{
            const selection = editor.editorState.getSelection();
            let contentState = editor.editorState.getCurrentContent();
            const thisBlock = contentState.getBlockForKey(selection.focusKey);
            if(thisBlock.getEntityAt(0) !== null){//리포스트 삭제하려할때 예외처리
                const type = contentState.getEntity(thisBlock.getEntityAt(0)).getType();
                if (type === 'repost') {
                    window.parent.postMessage({ name: 'editor', action: 'alert', text: '리포스트 영역은 삭제할 수 없습니다.' }, '*');
                    const doc = document.activeElement;//자동완성을 풀어줘야함
                    doc.blur();
                    return false;
                }
            }
        }

        if (e.keyCode === Keys.BACKSPACE){
            setCurrentFontStyle(editor);//적용된 스타일 툴바에 표시
            const block = getEditorBlock(editor.editorState);
            if(block.text === ''){//첫줄에 인용구있으면 삭제가능하게 처리
                const contentState = editor.editorState.getCurrentContent();
                const firstBlocks = contentState.getFirstBlock();
                if(block.key === firstBlocks.key){
                    editor.setEditorState(RichUtils.toggleBlockType(editor.editorState, 'unstyled'));
                }
            }
        }
        if (e.keyCode === Keys.DELETE){//리포스트 삭제하려할때 예외처리
            const selection = editor.editorState.getSelection();
            let contentState = editor.editorState.getCurrentContent();
            const textLength = getSelectedBlock(editor.editorState).characterList.size;
            if (textLength === selection.focusOffset) {//커서 마지막 length 체크
                const nextBlock = contentState.getBlockAfter(selection.focusKey);
                if (nextBlock != null) {
                    if (nextBlock.getEntityAt(0) !== null) {
                        const type = contentState.getEntity(nextBlock.getEntityAt(0)).getType();
                        if (type === 'repost') {
                            window.parent.postMessage({ name: 'editor', action: 'alert', text: '리포스트 영역은 삭제할 수 없습니다.' }, '*');
                            return false;
                        }
                    }
                }
            }
        }

        const kc = e.keyCode;
        if(kc === 8 || kc === 13 || (kc >= 65 && kc <= 90) || kc === 229 || (kc >= 48 && kc <= 57) || (kc >= 96 && kc <= 111) || kc === 32 || (kc >= 186 && kc <= 222)){//input
            window.parent.postMessage({ name: 'editor', action: 'changed', value: true }, '*');
        }
        if (e.keyCode === 229){//드래그해서 선택하고 한글 입력시, 이전에 드래그한 텍스트가 undo 되는 오류
            const selection = editor.editorState.getSelection();
            if (selection.anchorKey !== selection.focusKey) {
                let contentState = editor.editorState.getCurrentContent();
                const contentStateWithEntity = editor.editorState.getCurrentContent().createEntity('STXT', 'IMMUTABLE');
                contentState = Modifier.replaceText(contentState, editor.editorState.getSelection(), '', null, contentStateWithEntity.getLastCreatedEntityKey());
                editor.setEditorState(EditorState.push(editor.editorState,contentState,'insert-characters'));
            } 
        }
        if (e.keyCode === Keys.RETURN) {//엔터키 이벤트
            const selection = editor.editorState.getSelection();
            const target = editor.editorState.getCurrentContent().getBlockForKey(selection.getStartKey());//엔터키 입력시 포커싱된 블록 키
            if(target.type === 'UL_SQUARE' && target.text === ''){//이 타입일때 새 블록 생성후 빈값에서 엔터를 치면 블록이 해제됨.
                editor.setEditorState(RichUtils.toggleBlockType(editor.editorState, 'unstyled'));
                return false;
            }
            setTimeout(() => {
                const targetBlock = getSelectedBlock(editor.editorState);
                if(targetBlock.characterList.length === 0){
                    let contentState = editor.editorState.getCurrentContent();
                    contentState = Modifier.insertText(contentState, editor.editorState.getSelection(), '​', editor.editorState.getCurrentInlineStyle());
                    editor.setEditorState(EditorState.push(editor.editorState, contentState, 'insert-characters'));
                }
            }, 0);
            // let contentState = editor.editorState.getCurrentContent();
            // const block = getSelectedBlock(editor.editorState);
            // const beforeBlock = contentState.getBlockBefore(selection.focusKey);
            // if(beforeBlock != null){
            //     if(block.type.indexOf('QUOTE') > -1 && block.type === beforeBlock.type){
            //         let allBlocks = contentState.getBlocksAsArray();
            //         let idx = null;
            //         for(var i in allBlocks){
            //             if(allBlocks[i].key === selection.focusKey){
            //                 idx = i;
            //                 break;
            //             }
            //         }
            //         console.log(idx);
            //         console.log(allBlocks)
            //         // for(var i=idx-1; idx>=0; i--){
            //         //     console.log(allBlocks[i]);
            //         // }
            //         for(var i= Number(idx); i>=0; i--){
            //             console.log(i);
            //         }
            //     }
            // }
        }
        if (e.keyCode === 83 /* `S` key */ && hasCommandModifier(e)) {//ctrl + s
            const url = window.prompt('이미지 url을 입력하세요', '');
            InsertAtomic(editor, 'image', 'IMMUTABLE', { src:url});
            InsertAtomic(editor, 'textarea', 'IMMUTABLE', { text:'', split:false });
            return 'myeditor-save';
        }
        if(e.keyCode === Keys.LEFT){//zero width space 만나면 뛰어넘기
            var selection = editor.editorState.getSelection();
            var offset = selection.getAnchorOffset();
            var text = getSelectedBlock(editor.editorState).getText();
            var charBehind = text[offset - 2];
            if(charBehind === '​'){
                // const frontText = text.slice(0,offset-2);
                var cnt = 2;
                var flag = true;
                for (var i = offset - 2; i >= 0; i--) {
                    if (flag) {
                        text[i] === '​' ? cnt++ : flag = false;
                    }
                }
                editor.setEditorState(EditorState.forceSelection(editor.editorState, moveSelectionBackward(editor.editorState, cnt-1)));
            }
            setCurrentFontStyle(editor);//적용된 스타일 툴바에 표시
        }
        if(e.keyCode === Keys.RIGHT){//zero width space 만나면 뛰어넘기
            var selection = editor.editorState.getSelection();
            var offset = selection.getAnchorOffset();
            var text = getSelectedBlock(editor.editorState).getText();
            var charAhead = text[offset];
            if(charAhead === '​'){
                var cnt = 2;
                var flag = true;
                for (var i = offset + 1; i < text.length; i++) {
                    if (flag) {
                        text[i] === '​' ? cnt++ : flag = false;
                    }
                }
                editor.setEditorState(EditorState.forceSelection(editor.editorState, moveSelectionForward(editor.editorState, cnt)));
            }
            setCurrentFontStyle(editor);//적용된 스타일 툴바에 표시
        }
        if(e.keyCode === Keys.UP || e.keyCode === Keys.DOWN){
            setCurrentFontStyle(editor);//적용된 스타일 툴바에 표시
        }
        return getDefaultKeyBinding(e);
    }

    useEffect(() => {
        editor.toolbar ? editorComp.current.editorContainer.style.marginBottom = '100px' : editorComp.current.editorContainer.style.marginBottom = '0px';
        editor.toolbar ? editorComp.current.editorContainer.style.minHeight = '50px' : editorComp.current.editorContainer.style.marginBottom = '0px';
    }, [editor.editorState]);
    
    useEffect(() => {
    }, [editor.editorState.getSelection().focusKey]);

    const handleFocus = (e) => {//포커스 클릭editor.editorState.getSelection();
        //state를 변경하는 함수도 있어서 실행 순서 지정해줘야함.
        setTimeout(() => {
            if(isAppleApp()){
                // window.parent.postMessage({ name: 'editor', action: 'resizeFrame', height: window.innerHeight-300}, '*');
                window.postMessage({ name: 'test', action: 'resizeFrame', height: window.innerHeight-300}, '*');
            }
        }, 475);
        if(editor.toolbar){
            if(editor.editorState.getSelection().getHasFocus()){//에디터 클릭시 포커스가 가능한 영역이면 활성화
                editorComp.current.focus();
            }
            else{//포커스가 불가능한 영역이면 최 하단 선택하고 최 하단이 입력 가능한 영역이 아니라면 new line 생성
                editor.setEditorState(EditorState.moveFocusToEnd(editor.editorState));
                const content = editor.editorState.getCurrentContent();
                const blockType = content.getLastBlock().type;
                if(blockType !== 'unstyled' && !blockType.includes('TEXT_')){
                    editor.setEditorState(insertNewUnstyledBlock(editor.editorState));
                }
            }

            var selection = editor.editorState.getSelection();
            var offset = selection.getAnchorOffset();
            var text = getSelectedBlock(editor.editorState).getText();
            var charCurrent = text[offset];
            if(charCurrent === '​'){
                editor.setEditorState(EditorState.forceSelection(editor.editorState, moveSelectionForward(editor.editorState, 1)));
            }
            
            const idx = localStorage.getItem('idx-inline-style') != null ? Number(localStorage.getItem('idx-inline-style')) : null;
            if (idx != null && idx !== 0) {
                const key = localStorage.getItem('key-inline-style');
                const selection = editor.editorState.getSelection();
                let contentState = editor.editorState.getCurrentContent();
                contentState = Modifier.removeRange(
                    contentState,
                    new SelectionState({
                        anchorKey: key,
                        focusKey: key,
                        anchorOffset: idx,
                        focusOffset: idx + 1,
                        hasFocus: false
                    }),
                    "backward"
                );
                const newEditorState = EditorState.push(editor.editorState, contentState, 'backspace-character');
                const removeSpace = () => {//zero space 삭제하는건데, 모바일 자동완성과 ios는 적용하지않음.
                    editor.setEditorState(EditorState.forceSelection(newEditorState,
                        selection.focusKey === key ?
                        new SelectionState({
                            anchorKey: selection.anchorKey,
                            focusKey: selection.focusKey,
                            anchorOffset: idx < selection.anchorOffset ? selection.anchorOffset-1 : selection.anchorOffset,
                            focusOffset: idx < selection.focusOffset ? selection.focusOffset-1 : selection.focusOffset,
                            hasFocus: false
                        }) :
                        editor.editorState.getSelection()
                    ));//제자리로 focus
                }
                if(!editor.isPC){//모바일 안드로이드
                    if(localStorage.getItem('is-autocomplete') !== 'auto'){
                        setTimeout(() => {
                            removeSpace();

                            if(!editor.isPC){//포커싱 초기화를 위해 자판을 조정
                                const selection = editor.editorState.getSelection();
                                const doc = document.activeElement;//자동완성을 풀어줘야함
                                if (isAndroid()) {
                                    doc.blur();
                                    doc.focus();
                                }
                                else if (isAppleWeb() || isAppleApp()) {
                                    const init = document.querySelector('#memoryInit');
                                    init.focus();
                                    doc.focus();
                                }
                                let s = new SelectionState(selection);
                                editor.setEditorState(EditorState.forceSelection(editor.editorState, s));
                            }
                        }, 0);
                    }
                }
                else{
                    removeSpace();
                }
                localStorage.removeItem('key-inline-style');
                localStorage.removeItem('idx-inline-style');
                localStorage.removeItem('inline-style');
                localStorage.removeItem('add-inline-style');//토글 추가한 인라인 스타일
                localStorage.removeItem('del-inline-style');//토글 삭제한 인락인 스타일
                localStorage.removeItem('input-length-composition');
            }
            setCurrentFontStyle(editor);//적용된 스타일 툴바에 표시
        }
    }

    function ohiCompositionStart(e){
        const selection = editor.editorState.getSelection();
        if(selection.anchorKey !== selection.focusKey){//여러줄 드래그
            let contentState = editor.editorState.getCurrentContent();
            const blocks = getSelectedBlocksList(editor.editorState);
            let flag = false;
            blocks._tail.array.map(ev=>{
                if(ev.getEntityAt(0) !== null){
                    const type = contentState.getEntity(ev.getEntityAt(0)).getType();
                    if(type === 'repost'){
                        flag = true;
                    }
                }
            });
            if(flag){
                const doc = document.activeElement;//자동완성을 풀어줘야함
                doc.blur();
                // doc.focus();
                return false;
            }
        }
        else{
            localStorage.setItem('temp-offset', selection.focusOffset);//한글입력 시작 offset, backspace를 눌러도 end가 발생되는데 해당 offset으로 입력인지 backspace인지 확인
            if(selection.focusOffset !== selection.anchorOffset || selection.focusKey !== selection.anchorKey){//드래그로 블록잡고 입력시 드래그 영역 삭제
                deleteEditorSelection(editor);
            }
        }
    }

    function ohiCompositionEnd(e){
        const idx = localStorage.getItem('idx-inline-style');
        if (e.data === '') {//완료 이벤트를 backspace처리로 종료시켰을때 포커싱 처리
            var text = getSelectedBlock(editor.editorState).getText();
            var offset = editor.editorState.getSelection().focusOffset;
            var temp = Number(localStorage.getItem('temp-offset'));//임시 offset
            editor.setEditorState(EditorState.forceSelection(editor.editorState, new SelectionState({
                anchorKey: editor.editorState.getSelection().focusKey,
                focusKey: editor.editorState.getSelection().focusKey,
                anchorOffset: offset === 0 ? 0 : idx == null ? (temp > text.length ? temp-1 : temp) : temp - 1,
                focusOffset: offset === 0 ? 0 : idx == null ? temp > text.length ? temp-1 : temp : temp - 1,
                hasFocus: false,
            })));
            setCurrentFontStyle(editor);//적용된 스타일 툴바에 표시
        }
        localStorage.removeItem('temp-offset');
        localStorage.removeItem('idx-inline-style');
        localStorage.removeItem('inline-style');
        localStorage.removeItem('add-inline-style');//토글 추가한 인라인 스타일
        localStorage.removeItem('del-inline-style');//토글 삭제한 인락인 스타일
        localStorage.removeItem('input-length-composition');
    }

    function ohiKeydown(e) {
        var e = e || window.event, f = e.target || e.srcElement, n = f.nodeName || f.tagName;
        if (f.type === 'text' && n === 'INPUT' || n === 'TEXTAREA' || n === 'DIV') {
            // if (e.keyCode === Keys.BACKSPACE) { // Backspace
            if (e.keyCode === 8) { // Backspace
                if (editor.editorState.getSelection().getStartOffset() === editor.editorState.getSelection().getEndOffset()) {
                    var selection = editor.editorState.getSelection();
                    var offset = selection.getAnchorOffset();
                    var text = getSelectedBlock(editor.editorState).getText();
                    if(offset > 0){
                        var charBehind = text[offset - 1];
                        if(charBehind !== '​'){
                            e.stopPropagation();
                        }
                    }
                }
            }
            if (e.keyCode === 13) {
                editor.setEditorState(insertNewUnstyledBlock(editor.editorState));
                e.stopPropagation();
            }
            if (e.keyCode === 27) f.blur(); // Esc
        }
    }

    function ohiStart(l) {
        var ohiStatus = document.createElement('a');
        var ohiTimeout = 0;
        if (typeof (l) == 'string') {
            var kbd = ohiStatus.innerHTML.substr(2);
            if (l == 'KBD') ohiStatus.innerHTML = ohiStatus.innerHTML.substr(0, 2) + (!kbd ? ':QWERTZ' : kbd == ':QWERTZ' ? ':AZERTY' : '');
            else ohiStatus.innerHTML = ((l == 'En' || l == ohiStatus.innerHTML) ? 'En' : l.substr(0, 2)) + kbd;
        }
        if (document.body) {
            if (document.all) {
                if (ohiTimeout) clearTimeout(ohiTimeout);
                ohiTimeout = setTimeout("ohiStart()", 0);
            }
            if (document.body != ohiStatus.parentNode) {
                ohiStatus.style.display = 'none';
                document.body.appendChild(ohiStatus);
                if (document.addEventListener) {
                    document.addEventListener('keydown', ohiKeydown, true);
                } else {
                    document.onkeydown = ohiKeydown;
                }
                for (var i = 0; i < window.frames.length; i++) {
                    var ohi = document.createElement('script');
                    if (typeof (window.frames[i].document) != 'unknown') window.frames[i].document.body.appendChild(ohi);
                }
            }
        }
        else ohiTimeout = setTimeout("ohiStart()", 0);
    }

    function ohiCompositionUpdate(e){
        if(e.data.length > 1){
            if(localStorage.getItem('is-autocomplete') !== 'auto' ){
                localStorage.setItem('is-autocomplete', e.data.length > 1 ? 'auto' : null );
            }
        }
    }
    function onEditKeyDown(e){//영문 및 특문 입력시에도 구분자 삭제
        if(!editor.isPC){//모바일 안드로이드
            return false;
        }
        if(e.nativeEvent.keyCode !== 229 && e.nativeEvent.keyCode !== 37 && e.nativeEvent.keyCode !== 39){
            var selection = editor.editorState.getSelection();
            var offset = selection.getAnchorOffset();
            var text = getSelectedBlock(editor.editorState).getText();
            var charBehind = text[offset - 1];
            if(charBehind === '​'){
                let contentState = editor.editorState.getCurrentContent();
                contentState = Modifier.removeRange(
                    contentState,
                    new SelectionState({
                        anchorKey: selection.focusKey,
                        focusKey: selection.focusKey,
                        anchorOffset: selection.focusOffset - 1,
                        focusOffset: selection.focusOffset - 0,
                        hasFocus: true
                    }),
                    "backward"
                );
                const newEditorState = EditorState.push(editor.editorState, contentState, 'backspace-character');
                editor.setEditorState(newEditorState);
            }
        }
    }
    const onEditFocus = () => {//에디터 새글 누르고 첫 focus할때 default font, size 셋팅으로 인한 구분자 삭제
        const blocks = getAllBlocks(editor.editorState);
        if(blocks.size === 1){
            const blockText = getSelectedBlock(editor.editorState).text;
            if(blockText === '​​'){
                setTimeout(() => {
                    editor.setEditorState(clearEditorContent(editor.editorState));
                }, 0);
            }
        }
    }
    return (
        <React.Fragment>
            <div ref={editFrame} 
                className={classes.main} 
                onClick={handleFocus} 
                style={{overflow: !editor.toolbar?'hidden':null}} 
                onCompositionUpdate={ohiCompositionUpdate}
                onCompositionStart={ohiCompositionStart}
                onCompositionEnd={ohiCompositionEnd}
                onKeyDown={onEditKeyDown}
                id="editMain"
                /*onDragOver={(e) => divHandleDragOver(e)} onDrop={divHandleDrop}*/
            >
            {
                editor.isLoading?
                <Lottie
                    options={lottieOptions}
                    isStopped={false}
                    isPaused={false}
                    isClickToPauseDisabled={false}
                    style={{width: '100px', height: '130px'}} // svg의 부모 div에 적용
                    eventListeners={[
                        {
                            eventName: 'complete',
                            callback: () => console.log('the animation completed'),
                        },
                    ]}
                /> 
                :null
            }
                <DraftEditor 
                    editorState={editor.editorState}
                    onChange={editor.setEditorState}
                    onFocus={onEditFocus}
                    customStyleMap={customStyleMap}
                    ref={editorComp}
                    blockStyleFn={blockStyleFn}
                    blockRendererFn={blockRenderer}
                    blockRenderMap={extendedBlockRenderMap}
                    handlePastedFiles={handlePastedFiles}
                    handlePastedText={handlePastedText}
                    handleDroppedFiles={handleDroppedFiles}
                    keyBindingFn={keyBindingFn}
                    readOnly={editor.readOnly}
                />
            </div>
            <input type='text' id='memoryInit' style={{position:'absolute', top:'-100px'}} />
        </React.Fragment>
    )
}
export default inject(({ editor }) => ({
    editor: editor,
}))(observer(Editor));