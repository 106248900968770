import { observer, inject } from 'mobx-react'
import React from 'react'
import InsertLink from '../../InsertLink'
import { InsertOpenGraph } from '../../InsertOpenGraph'
import InsertVideo from '../../InsertVideo'

function LeftFunctionIconBinder({e, editor , setHandleFontSize, viewFontSize, fontsize, onAction}) {
    if(e.fontsize){
        return (
            <div style={{
                height:'100%',
                width:'32px',
                display:'inline-flex'
            }}
            onClick={setHandleFontSize} onMouseDown={(e) => e.preventDefault()}
            >
                <span style={{margin:'auto'}} className="text item" >
                    {viewFontSize != null ? viewFontSize : fontsize}
                </span>
            </div>
        )
    }
    else{
        if(e.custom){
            if(e.custom === 'link'){
                return <InsertLink/>
            }
            else if(e.custom === 'video'){
                return <InsertVideo/>
            }
            else if(e.custom === 'openGraph'){
                return <InsertOpenGraph editor={editor}/>
            }
        }
        else{
            return(
                <div style={{height:'100%', display:'inline-flex'}} 
                    onClick={() => e.onClick(onAction,editor)}
                    onMouseDown={(e) => e.preventDefault()}
                >
                    <img
                        className="item"
                        src={
                            editor.fontInlineStyle != null ? (editor.fontInlineStyle[e.name] ? e.activeIcon : e.icon) : e.icon
                        }
                        alt=""
                        style={{margin:'auto'}}
                    />
                </div>
            )
        }
    }
}

export default inject(({ editor }) => ({
    editor: editor
}))(observer(LeftFunctionIconBinder));
