import React, { useRef } from 'react';
import ImageAtomic from './ImageAtomic';
import VideoAtomic from './VideoAtomic';
import YoutubeAtomic from './YoutubeAtomic';
import NewsAtomic from './NewsAtomic';
import BlockSplitter from '../func/blockSplitter';
import SliderAtomic from './SliderAtomic';
import CollageAtomic from './CollageAtomic';
import TextareaAtomic from './TextareaAtomic';
import { observer, inject } from 'mobx-react';
import OpenGraphAtomic from './OpenGraphAtomic';
import NewsLinkAtomic from './NewsLinkAtomic';
import EventImageAtomic from './EventImageAtomic';

const AtominController = ({ contentState, block, editor }) => {
    var media = null;
    const atomic = useRef();
    const splitter = (children) => {
        return  <React.Fragment>
                    <div >
                        {children}
                        <BlockSplitter blockKey={block.key} />
                    </div>
                </React.Fragment>
    }

    if(block.getEntityAt(0)){
        const entity = contentState.getEntity(block.getEntityAt(0));
        const { src, title } = entity.getData();
        const type = entity.getType();
        if (type === 'image') {
            media = splitter(<ImageAtomic block={block} type={type} src={src} />);
        }
        if (type === 'eventImage') {
            media = splitter(<EventImageAtomic block={block} type={type} src={src} />);
        }
        if (type === 'youtube') {
            media = splitter(<YoutubeAtomic block={block} type={type} src={src} title={title} />);
        }
        if (type === 'video') {
            media = splitter(<VideoAtomic block={block} type={type} src={src} />);
        }
        if (type === 'news') {
            media = splitter(<NewsAtomic block={block} type={type} item={entity.getData()} />);
        }
        if (type === 'repost') {
            media = splitter(<NewsAtomic block={block} type={type} item={entity.getData()} />);
        }
        if (type === 'newsLink') {
            media = splitter(<NewsLinkAtomic block={block} type={type} item={entity.getData()} />);
        }
        if (type === 'slider') {
            media = splitter(<SliderAtomic block={block} type={type} src={src} />);
        }
        if (type === 'collage') {
            media = splitter(<CollageAtomic block={block} type={type} src={src} />);
        }
        if (type === 'openGraph') {
            media = splitter(<OpenGraphAtomic block={block} type={type} data={entity.getData()} />);
        }
        if (type === 'textarea') {//textarea는 splitter 없음
            media = <TextareaAtomic block={block} type={type} data={entity.getData()} />
        }
    }

    const handleClick = () => {//atomic 선택시 selection이 맞지않는부분 보정(textarea외에는 이거)
        // if(editor.isMobile){//모바일일때는 포커스 옮겨준다.
        //     const blockKey = atomic.current.parentElement.dataset.offsetKey.replace("-0-0","");
        //     let selection = new SelectionState({
        //         anchorKey: blockKey,
        //         anchorOffset: 999,
        //         focusKey: blockKey,
        //         focusOffset: 0,
        //         hasFocus: true,
        //         isBackward: false
        //     });
        //     let s = new SelectionState(selection);
        //     editor.setEditorState(EditorState.acceptSelection(editor.editorState, s));
        // }
        // else{//PC는 이중포커스가 잡혀서 일단 홀딩, 커서가 사라지지않는데 메모리의 포커스는 옮겨져서 이상태에서 타이핑시 오동작
        // }
    }
    return (
        <React.Fragment>
            <div ref={atomic} onClick={handleClick} style={{position:'relative'}}>
                {media}
            </div>
        </React.Fragment>
    );
};

export default inject(({ editor }) => ({
    editor: editor,
}))(observer(AtominController));