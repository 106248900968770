import { inject, observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { Typography, Popper } from '@material-ui/core';
import Truncate from 'react-truncate';
import { getOG } from './../../common/interface/common';
import { root_path } from '../../common/constants';
import { DeleteAtomic, getAtomicPosition, MoveAtomic, MoveAtomic2, getAtomicOffsetTop } from './AtomicFunction';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const OpenGraphAtomic = ({editor, block, type ,data}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const item = useRef();
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const handleClick = (event) => {
        if(!editor.toolbar){
            window.open(data.url);
        }
        else{
            setAnchorEl(anchorEl ? null : event.currentTarget);
        }
    }
    
    const moveScroll = () => {
        if(item != null){
            setTimeout(() => {
                const offsetTop = getAtomicOffsetTop(item);
                window.postMessage({ name: 'scrollDown', offsetTop: offsetTop - 100}, '*') //뉴스 아웃링크
            }, 0);
        }
    }
    const moveDownwardAtomic = () => {
        MoveAtomic(editor, block, 'after');
        moveScroll();
    }
    const moveUpwardAtomic = () => {
        MoveAtomic(editor, block, 'before');
        moveScroll();
    }
    return (
        <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width:'calc(100% - 32px)', display: 'flex', justifyContent: 'center', margin:'auto', padding: '7px 0px' }} onClick={handleClick} ref={item}>
                    <div style={{ maxWidth:'328px', minWidth:'328px', 
                    // height:'316px'
                    }}>
                        {data &&
                            <div style={{
                                position: 'relative', 
                                // height:'145px',
                                // boxShadow: '0px 2px 9px rgba(0, 0, 0, 0.12)',
                                border: '1px solid rgb(226, 226, 226)',
                                borderRadius: '5px 5px 7px 7px'
                            }}>
                                <div style={{
                                    // boxShadow: '0px 2px 9px rgba(0, 0, 0, 0.12)',
                                    // borderRadius: '5px 5px 0px 0px'
                                }}>
                                    <div>
                                        <Typography style={{
                                            fontSize:'12px',
                                            padding: '6px 14px',
                                            paddingTop: '12px',
                                            opacity: '0.5',
                                            letterSpacing: '0px',
                                            fontFamily: 'Roboto',
                                        }} className='singleLineHide'>{data.url}</Typography>
                                    </div>
                                    <div style={{padding:'6px 14px', paddingBottom:'12px'}}>
                                        <Typography style={{
                                            fontWeight:'bold',
                                            fontSize: '16px',
                                            lineHeight: '22px',
                                            WebkitLineClamp: '2',
                                            fontFamily: 'Noto Sans KR'
                                        }} className='multiLineHide'>{data.title}</Typography>
                                        <div style={{height:'6px'}}/>
                                        <Typography>
                                            <Truncate lines={2} style={{ 
                                                fontSize: '14px', 
                                                lineHeight: '20px',
                                                WebkitLineClamp: '2',
                                                fontFamily: 'Noto Sans KR'
                                            }} className='multiLineHide'>{data.description}</Truncate>
                                        </Typography>
                                    </div>
                                </div>
                                {data.src&&
                                    <div style={{
                                        // height:'172px', maxHeight:'172px,'
                                        overflow:'hidden', backgroundColor:'white', borderRadius:'5px 5px 5px 5px' }}>
                                        <img
                                            src={data.src}
                                            alt='THUMNAIL'
                                            style={{ display: 'block', width: '100%', height: '100%'}}
                                        />
                                    </div>
                                }
                                <img src={root_path + '/images/transparentImg.png'} style={{ position: 'absolute', top: 0, width: '100%', height: '316px'}} alt='___'/>
                                
                            </div>
                        }
                    </div>
                    {
                        <Popper id={id} open={open} anchorEl={anchorEl} placement='top'
                            modifiers={{
                                flip: {enabled: false,},
                                preventOverflow: {enabled: false,},
                                offset: {
                                  enabled: true,
                                  offset: '0px 20px -50px -20px'
                                }
                            }}
                            style={{background:'white', borderRadius: '5px'}}
                        >
                            <div style={{ cursor: 'pointer' }}>
                                <ArrowUpwardIcon onClick={(e) => moveUpwardAtomic(block, editor)} style={{padding:'5px 5px 6px 5px', marginBottom:'-5px'}} />
                                <ArrowDownwardIcon onClick={(e) => moveDownwardAtomic(block, editor)} style={{padding:'5px 5px 6px 5px', marginBottom:'-5px'}} />
                                <DeleteForeverOutlinedIcon onClick={(e) => DeleteAtomic(block, editor)} style={{padding:'5px 5px 6px 5px', marginBottom:'-5px'}} />
                            </div>
                        </Popper>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
export default inject(({ editor }) => ({
    editor: editor,
}))(observer(OpenGraphAtomic));
