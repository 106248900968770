import { inject, observer } from 'mobx-react';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

const RepostStyled = styled.span`
    color: #7875c9;
    font-size: 15px;
    margin: 12px 0 0;
    font-weight: 800;
`;

const NewsAtomic = ({ editor, item}) => {

    const handleClick = (event) => {
        if(editor.readOnly){
            //뉴스 클릭시 이벤트
            item.nid ? 
            window.parent.postMessage({ name: 'new_window', action:'outlink', type: 'news', 'item': item}, '*') //뉴스 아웃링크
            : 
            window.parent.postMessage({ name:'new_window', action:'transform', type: 'post', 'item': item}, '*') //포스트 아웃링크
        }
    };

    return (
        <React.Fragment>
            <RepostStyled style={{ cursor: 'pointer' }} onClick={handleClick}>
                기사보기
            </RepostStyled>
        </React.Fragment>
    )
}

export default inject(({ editor }) => ({
    editor: editor,
}))(observer(NewsAtomic));