import { AtomicBlockUtils, EditorState, Modifier, SelectionState } from "draft-js";
export const InsertAtomic = (editor, type, subType, param) => {
    const contentState = editor.editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(type, subType, param);
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editor.editorState, { currentContent: contentStateWithEntity });
    editor.setEditorState(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ', param.split==null?true:false));//split은 atomic 생성될때 생기는 빈 블록 여부, 기본 : true
}
export const MoveAtomic = (editor, block, direction) => {
    if(direction != null){
        const contentState = editor.editorState.getCurrentContent();
        const targetKey = direction === 'after' ? contentState.getKeyAfter(block.key) : contentState.getKeyBefore(block.key);
        if(targetKey != null){
            const targetSelection = new SelectionState({
                focusKey: targetKey,
                anchorKey: targetKey
            });
            const newEditorState = AtomicBlockUtils.moveAtomicBlock(editor.editorState, block, targetSelection, direction);
            setTimeout(() => {
                editor.setEditorState(newEditorState);//split은 atomic 생성될때 생기는 빈 블록 여부, 기본 : true
            }, 0);
        }
    }
}

export const MoveAtomic2 = (editor, block, targetKey) => {
    //이미지 드래그 앤 드롭 테스트 기능, 모바일 및 마우스 드롭 포지션의 제약조건이 많아서 버튼으로 대체 후 홀드


    // if(block.key !== targetKey){
    //     const contentState = editor.editorState.getCurrentContent();
    //     const blocks = contentState.getBlockMap();
    //     let lenBlock = null;
    //     let lenTarget = null
    //     blocks._list._tail.array.map((e,i)=>{
    //         if(e[0] === block.key){lenBlock = i}
    //         if(e[0] === targetKey){lenTarget = i}
    //     });
    //     const direction = lenBlock < lenTarget ? 'after' : 'before';
    //     const targetSelection = new SelectionState({
    //         focusKey: targetKey,
    //         anchorKey: targetKey,
    //         focusOffset: 0,
    //         anchorOffset: 0
    //     });
    //     console.log(direction)
    //     const newEditorState = AtomicBlockUtils.moveAtomicBlock(editor.editorState, block, targetSelection, direction);
    //     // setTimeout(() => {
    //         editor.setEditorState(newEditorState);//split은 atomic 생성될때 생기는 빈 블록 여부, 기본 : true
    //     // }, 0);
    // }
}


export const getAtomicType = (editor) => {
    const key = editor.editorState.getSelection().focusKey;
    const block = editor.editorState.getCurrentContent().getBlockForKey(key);
    const type = block != null ? block.getEntityAt(0) != null ? editor.editorState.getCurrentContent().getEntity(block.getEntityAt(0)).getType() : null : null;
    return type;//null인건 현재로서는 pc에서 입력한 plaintext 같음
}

export const getAtomicOffsetTop = (ref) => {
    let node = ref.current;
    while(node.nodeName !== 'FIGURE'){
        node = node.parentNode;
    }
    return node.offsetTop != null ? Number(node.offsetTop) : 0;
}

export const DeleteAtomic = (block, editor) => {
    const key = block.getKey();
    let contentState = editor.editorState.getCurrentContent();

    const targetRange = new SelectionState({
        anchorKey: key,
        anchorOffset: 0,
        focusKey: key,
        focusOffset: block.getLength()
    });

    let newContentState = Modifier.removeRange(
        contentState,
        targetRange,
        "backward"
    );

    var resetBlock = Modifier.setBlockType(
        newContentState,
        newContentState.getSelectionAfter(),
        'unstyled'
    );

    const newEditorState = EditorState.push(editor.editorState, resetBlock, 'remove-range')

    editor.setEditorState(newEditorState)
}