import { inject, observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { DeleteAtomic, InsertAtomic } from './AtomicFunction';
import { EditorState, Modifier, SelectionState, RichUtils, convertToRaw } from 'draft-js';
import { TextareaAutosize } from '@material-ui/core';
import { blockTypeChange } from '../func/FontFunction';
import { getOG } from './../../common/interface/common';
import { insertNewUnstyledBlock } from 'draftjs-utils';
import { MENTION_REGEX } from './../strategy/mention/MentionStrategy';

const TextareaAtomic = ({ editor, block, type ,data}) => {
    const [text, setText] = useState();
    const [selected , setSelected] = useState();
    const textarea = useRef();
    const removeBlock = (block, blockKey) => {
        const targetRange = new SelectionState({
            anchorKey: blockKey == null ? block.getKey() : blockKey,
            anchorOffset: 0,
            focusKey: blockKey == null ? block.getKey() : blockKey,
            focusOffset: 0
        });
        let newContentState = Modifier.removeRange(
            editor.editorState.getCurrentContent(),
            targetRange,
            "backward"
        );
        var resetBlock = Modifier.setBlockType(
            newContentState,
            newContentState.getSelectionAfter(),
            'unstyled'
        );
        const newEditorState = EditorState.push(editor.editorState, resetBlock, 'remove-range')
        editor.setEditorState(newEditorState);
    }
    
    useEffect(() => {//mobile에서 작성한 글을 PC에서 조회할때 , 저장한 당시에 textarea이기때문에 atomic이 실행되고 자동으로 block으로 치환한다
        const convertTextareaToBlock = () => {
            const rawData = convertToRaw(editor.editorState.getCurrentContent());
            const entity = Object.values(rawData.entityMap);
            rawData.blocks.map(e=>{
                if(e.entityRanges.length>0){
                    if(entity[e.entityRanges[0].key].type === 'textarea'){
                        removeBlock(null,e.key);//원본 블록 삭제
                        let newSelection = new SelectionState({
                            anchorKey: e.key,
                            anchorOffset: 0,
                            focusKey: e.key,
                            focusOffset: 9999,//마지막 length 어떻게 찾을지, 이게 substring 같은거임
                            hasFocus: true
                        });
                        let contentState = editor.editorState.getCurrentContent();
                        contentState = Modifier.replaceText(contentState, newSelection, entity[e.entityRanges[0].key].data.text, null, null);
                        editor.setEditorState(EditorState.push(editor.editorState,contentState,'insert-characters'));
                    }
                }
            });
        }
        textarea.current.focus();
        if(!editor.toolbar){//뷰 화면
            convertTextareaToBlock();
        }
        else{
            if(!editor.isMobile){//PC일때는 편집을 위해
                convertTextareaToBlock();
            }
        }
    }, []);
    const keyHandler = (e) => {
        if(e.keyCode === 8 && e.target.value === ""){//백스페이스
            ///////////////////////////블록 삭제시 blockSplitter 도 같이 제거///////////////////
            var contents = editor.editorState.getCurrentContent();
            var beforeBlock = contents.getBlockBefore(selected);
            let removeSelection = new SelectionState({
                anchorKey: selected,
                anchorOffset: 9999,
                focusKey: selected,
                focusOffset: 0
            });
            let newContentState = Modifier.removeRange(
                contents,
                removeSelection,
                "backward"
            );
            if(beforeBlock != null){
                editor.setEditorState(EditorState.push(editor.editorState, newContentState, 'remove-range'));//줄 삭제
                editor.setReadOnly(false);
            }
            // if(beforeBlock == null){//첫줄일때 블록 스타일도 제거
            //     // blockTypeChange(editor.setEditorState, editor.editorState, 'unstyled');
            // }
            // DeleteAtomic(block, editor);
        }
        // const selection = editor.editorState.getSelection();
        // const target = editor.editorState.getCurrentContent().getBlockForKey(selection.getStartKey());//엔터키 입력시 포커싱된 블록 키
        // console.log(target)
    }
    const save = (e) => {//onBlur
        if(editor.isMobile){
            if(text){
                removeBlock(block);//원본 블록 삭제
                InsertAtomic(editor, 'textarea', 'IMMUTABLE', { text: text , split: false});//작성된 값으로 atomic 생성
            }
            editor.setReadOnly(false);
        }
    }
    const modify = (e) => {//onFocus ,textarea를 수정할때는 editor의 readonly가 true여야 수정 가능함.
        if(editor.isMobile){
            editor.setReadOnly(true);
            setSelected(textarea.current.parentElement.parentElement.dataset.offsetKey.replace("-0-0",""));// 선택한 시점에서 selection이 이전값으로 따라와서 강제 선택
        }
    }

    const inputHandler = (e) => {//url 표현식이 입력되면 atomic 생성, 분기처리 필요하고, 이왕이면 입력 후 엔터키도 입력받고싶음
        const regex = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm);
        if(regex.test(e.target.value)){
            // console.log(e.target.value)
            setText(e.target.value.replace(e.target.value.match(regex).toString() ,''));
            create(e.target.value.match(regex).toString());
        }
        else{
            setText(e.target.value);
        }
    }
    
    const create = (text) => {
        var url = text;
        var check = false;
        if (url.indexOf('www') > -1) {
            const count = (url.match(/\./g) || []).length;
            if (count > 1) {
                check = true;
            }
        } else {
            check = true;
        }
        if (check) {
            getOG(url).then(function (response) {
                if (response) {
                    editor.setEditorState(insertNewUnstyledBlock(editor.editorState));
                    window.postMessage({ type: 'SET', param: { type: 'openGraph', action: {
                        ogUrl : response.ogUrl == null ? response.requestUrl : response.ogUrl,
                        ogTitle : response.ogTitle,
                        ogDescription : response.ogDescription,
                        ogImage : response.ogImage == null ? null : response.ogImage.url
                    }}}, '*');
                }
                else{
                    // console.log('틀린 url')
                }
            })
        }
    }

    useEffect(() => {
        // const REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm
        // const regex = new RegExp(REGEX);
        // if(regex.test(text)){
        //     // console.log(text.match(regex));
        //     text.match(regex).map(e=>create(e));
        // }
        // window.parent.postMessage({ name: 'editor', action: 'isNull', value: false }, '*');
    }, [text]);

    return (
        <TextareaAutosize 
            rowsMin={1} 
            onFocus={modify} 
            onBlur={save} 
            defaultValue={data.text} 
            onChange={inputHandler}
            onKeyDown={keyHandler}
            style={{
                border: 'none'
            }}
            ref={textarea}
            disabled={!editor.toolbar}
            value={text}
        />
    )
}
export default inject(({ editor }) => ({
    editor: editor,
}))(observer(TextareaAtomic));
