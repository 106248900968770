import { observer,inject } from 'mobx-react';
import React, { useCallback, useRef, useState } from 'react'
import ImageViewer from "react-simple-image-viewer";

function Viewer({editor, src}) {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const images = [src];
    const imgRef = useRef();
    const openImageViewer = useCallback(index => {
        if(!editor.toolbar){
            setCurrentImage(index);
            setIsViewerOpen(true);
        }
    }, []);

    const closeImageViewer = () => {
        if(!editor.toolbar){
            setCurrentImage(0);
            isViewerOpen ? setIsViewerOpen(false) : setIsViewerOpen(true);
        }
    };
    const handleClose = () => {
        // var ua = window.navigator.userAgent;
        // if (ua.indexOf('iPhone ') > 0 || ua.indexOf('iPad ') > 0) {
        //     window.opener = null;
        //     window.open('', '_self');
        //     window.close();
        // } else {
        //     window.close();
        // }
        editor.isApp ? window.ReactNativeWebView.postMessage({name : 'close'}, '*') : window.close();

    }
    return (
        <div>
            <div onClick={closeImageViewer}>
                {images.map((src, index) => (
                    <img
                        src={src}
                        onClick={() => openImageViewer(index)}
                        key={index}
                        style={{
                            margin: "auto",
                            display:'block',
                            maxWidth: '90%'
                        }}
                        alt=""
                        ref={imgRef}
                    />
                ))}

                {isViewerOpen && (
                    <ImageViewer
                        src={images}
                        currentIndex={currentImage}
                        onClose={closeImageViewer}
                        backgroundStyle={{
                            backgroundColor: "rgba(0,0,0,0.9)",
                            width: '100%',
                            padding: '0px',
                        }}
                    />
                )}
            </div>
        </div>
    )
}

export default inject(({ editor }) => ({
    editor: editor,
}))(observer(Viewer));