import React from 'react'
import { makeStyles} from '@material-ui/core';
import { observer, inject } from 'mobx-react';
import { removeSelectedBlocksStyle , getEntityRange, getSelectionEntity} from 'draftjs-utils';
import { EditorState, Modifier, SelectionState } from 'draft-js';
import { OrderedSet } from 'immutable';
import { InsertAtomic } from '../atomic/AtomicFunction';
import { focusTextarea, insertNewTextarea } from './TextAreaFunction';

const useStyles = makeStyles(theme => ({
    splitter: {
        height:'15px',
        position: 'relative',
        cursor: 'pointer',
        left: 0,
        right: 0,
        width: '100%',
        // maxWidth:'320px',
        margin: '0 auto',
        '&:after': {
            content: '""',
            width: '100%',
            background: '#e4e4e4',
            height:'3px',
            position: 'absolute',
            visibility: 'hidden',
            top: '6px',
            opacity: '0',
        },
        '&:hover:after': {
            transitionProperty: 'opacity',
            transitionDelay: '.05s',
            transitionDuration: '.3s',
            visibility: 'inherit',
            opacity: '1'
        }
    }
}));

/*
    -생성조건
    1.atomic 사이에 존재
    2.atomic중에 textarea 사이에는 생성하면 안됨
    3.블록 마지막이 textarea면 생성하면 안됨
*/
function BlockSplitter({editor, blockKey}) {
    const classes = useStyles();
    const split = () => {
        const content = editor.editorState.getCurrentContent();
        const blockMap = content.getBlockMap();
        const block = blockMap.get(blockKey);
        const selection = new SelectionState({
            anchorKey: block.getKey(), // key of block
            anchorOffset: block.getLength(),
            focusKey: block.getKey(),
            focusOffset: block.getLength(), // key of block
            hasFocus: true,
            isBackward: false // isBackward = (focusOffset < anchorOffset)
        });
        const entityKey = Modifier.splitBlock(content, selection);
        editor.setEditorState(EditorState.push(editor.editorState, entityKey,'split-block'));//개행
        editor.setEditorState(removeSelectedBlocksStyle(editor.editorState));//개행 후 블록 스타일 삭제
        const afterKey = editor.editorState.getCurrentContent().getKeyAfter(blockKey);//blockSplitter로 textarea 생성시 focus가 옮겨지지않아서 forceSelect
        let selection2 = new SelectionState({
            anchorKey: afterKey,
            anchorOffset: 999,
            focusKey: afterKey,
            focusOffset: 0,
            hasFocus: true,
            isBackward: false
        });
        let s = new SelectionState(selection2);
        editor.setEditorState(EditorState.forceSelection(editor.editorState, s));
    }
    const checkVisible = () => {
        const nextBlock = editor.editorState.getCurrentContent().getBlockAfter(blockKey);
        let flag = true;
        //다음 블록의 유형, atomic 내에서도 type이 여러가지임 (image, youtube, textarea ...)
        const type = nextBlock != null ? nextBlock.getEntityAt(0) != null ? editor.editorState.getCurrentContent().getEntity(nextBlock.getEntityAt(0)).getType() : null : null;
        if(nextBlock != null && (nextBlock.type === "unstyled" || nextBlock.type.includes("TEXT_") || type === 'textarea')){
            flag = false;
        }
        return flag;
    }
    return (
        <React.Fragment>
            {
                checkVisible()&&editor.toolbar&&
                <div className={classes.splitter} onClick={split} onMouseDown={split} contentEditable={'false'} />
            }
        </React.Fragment>
    )
}

export default inject(({ editor }) => ({
    editor: editor,
}))(observer(BlockSplitter));