import React from 'react';
import { observer, inject } from 'mobx-react';
export const LeafComponent = (props) => {
    const styles = {
        leaftag: {
            color: "green",
            // width: '20px',
            // display: 'inline-block',
        }
    };
    return (
        <div style={{display:'inline-table',overflow: 'hidden'}} >
            <div style={{ marginLeft: '-17px'}}>
            <span style={styles.leaftag} data-offset-key={props.offsetKey}>
                {props.children}
            </span>
            </div>
        </div>            
    );
};
export default inject(({ editor }) => ({
    editor: editor,
}))(observer(LeafComponent));
