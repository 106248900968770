import React, { useState } from 'react';
import styled from 'styled-components';
// import { ReactComponent as Close } from 'asset/icon9/ic_closed.svg';
// import theme from 'theme';
import { observer,inject } from 'mobx-react';
import { isAppleApp, isAppleWeb } from './../../../../common/interface/common';

const MenuBox = styled.div`
    display: ${({ show }) => (show ? 'flex' : 'none')};
    position: absolute;
    left: -10.5px;
    top: 48px;
    width: 148px;
    box-sizing: border-box;
    background-color: white;
    z-index: 3;
    border-bottom: 1px solid #e2e2e2;
    border-right: 1px solid #e2e2e2;
    border-left: 1px solid #e2e2e2;
    font-size: 12px;
    overflow: auto;
    ul {
        width: 100%;
        display: flex;
        flex-direction: column;
        li {
            box-sizing: border-box;
            padding: 12px 14px;
            height: 48px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e2e2e2;
            img {
                display: block;
                height: 24px;
                width: auto;
            }
        }
        li:last-child{
            border-bottom: none;
        }
    }
`;

const MenuBoxApp = styled.div`
    display: ${({ show }) => (show ? 'flex' : 'none')};
    position: absolute;
    left: -10.5px;
    bottom: 48px;
    width: 148px;
    box-sizing: border-box;
    background-color: white;
    z-index: 3;
    border-top: 1px solid #e2e2e2;
    border-right: 1px solid #e2e2e2;
    border-left: 1px solid #e2e2e2;
    font-size: 12px;
    overflow: auto;
    ul {
        width: 100%;
        display: flex;
        flex-direction: column;
        li {
            box-sizing: border-box;
            padding: 12px 14px;
            height: 48px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e2e2e2;
            img {
                display: block;
                height: 24px;
                width: auto;
            }
        }
        li:last-child{
            border-bottom: none;
        }
    }
`;

const DropdownBlock = styled.div`
    position: relative;
    height: 100%;
    z-index: 0;
`;

const EditSubDropdown = ({ children, show, onShow, fontBoxHeight, editor , ...rest }) => {
    return (
        <DropdownBlock onClick={onShow} fontBoxHeight={fontBoxHeight} {...rest}>
            {children.slice(0, 1)}
            {
                // editor.isApp?
                // // <MenuBoxApp style={{height:isAppleWeb()||isAppleApp()?'35vh':'62vh'}} show={show}>{children.slice(1)}</MenuBoxApp>
                // <MenuBoxApp style={{height: fontBoxHeight === null ? null : `${fontBoxHeight}px`}} show={show}>{children.slice(1)}</MenuBoxApp>
                // :
                <MenuBox show={show}>{children.slice(1)}</MenuBox>
            }
        </DropdownBlock>
    );
};

export default inject(({ editor }) => ({
    editor: editor
}))(observer(EditSubDropdown));

