import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinkIcon from '@material-ui/icons/Link';
import { root_path } from './../../common/constants';


export const InsertLink = () => {
    const [open, setOpen] = useState(false);
    const [link, setLink] = useState('');

    const handleChange = (e) => {
        setLink(e.target.value);
    }
    const handleClickOpen = () => {
        setLink('');
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    
    const handlePutLink = (e) => {
        // const url = window.prompt('링크 url을 입력하세요','http://naver.com');
        window.postMessage({ type: 'SET', param: { type: 'link', action: link } }, '*');
        setOpen(false);
        return "handled";
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handlePutLink(e);
        }
    }
    return (
        <React.Fragment>
        {
            // <LinkIcon style={{
            //     cursor: 'pointer',
            //     height: '30px',
            //     margin: '10px 0px',
            //     display: 'flex', justifyContent: 'center', alignItems: 'center',
            // }}
            // onClick={handleClickOpen}/>
        }   
            <div 
                style={{height:'100%', display:'inline-flex'}}
                onMouseUp={handleClickOpen}
            >
                <img
                    className="item"
                    src= {root_path + '/icon/editor/ic_editor_link@2x.png'}
                    alt=""
                    style={{margin:'auto'}}
                    // onMouseDown={(e) => e.preventDefault()}
                />
            </div>
            <Dialog fullScreen open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" style={{backgroundColor: 'black', opacity: '0.8', color: 'white'}}>URL 링크 첨부</DialogTitle>
                <DialogContent style={{backgroundColor: 'black', opacity: '0.8', color: 'white'}}>
                    <TextField
                        autoFocus
                        margin="dense"
                        // label="URL을 입력하세요."
                        type="text"
                        fullWidth
                        value={link}
                        onChange={(e) => handleChange(e)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        style={{color:'white'}}
                        placeholder="링크 URL을 입력해주세요."
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">취소</Button>
                    <Button onClick={handlePutLink} color="primary">확인</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>        
    )
}

export default InsertLink;
