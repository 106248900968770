import React from 'react'
import { getSelectedBlock, getSelectionInlineStyle, getBlockBeforeSelectedBlock } from 'draftjs-utils';
import { fontFamily, fontSize } from '../../common/constants';
import { EditorState, Modifier } from 'draft-js';
import { defaultFont, defaultFontSize } from '../../components/tools/support/MToolbar/EditBar';

export function getEditorText(editorState){
    var selection = editorState.getSelection();
    var content = editorState.getCurrentContent();
    var key = selection.getAnchorKey();
    var offset = selection.getAnchorOffset();
    var text = content.getBlockForKey(key).getText();
    return
}
export function getEditorBlock(editorState){
    return getSelectedBlock(editorState);
}

export function deleteEditorSelection(editor){
    let contentState = editor.editorState.getCurrentContent();
    contentState = Modifier.removeRange(contentState, editor.editorState.getSelection(), 'backward');
    editor.setEditorState(EditorState.push(editor.editorState,contentState,'remove-range'));
}

export function setCurrentFontStyle(editor){//적용된 스타일 툴바에 표시
    setTimeout(() => {
        const selection = editor.editorState.getSelection();
        if(selection.focusOffset === selection.anchorOffset){
            const getIndexStyleAt = (idx) => {//현재 offset의 적용된 style get
                const root = getSelectedBlock(editor.editorState).getInlineStyleAt(idx)._map._map._root;
                return root != null ? root.entries : null;
            }
            const styles = getIndexStyleAt(selection.focusOffset === 0 ? 0 : selection.focusOffset-1);
            if(styles != null){
                let styleFlag = false;
                let sizeFlag = false;
                for(var i in styles){
                    for(var j in fontFamily){
                        if(styles[i][0] === fontFamily[j].key){
                            styleFlag = true;
                            editor.setFontStyle(fontFamily[j].viewText);
                            break;
                        }
                    }
                    for(var j in fontSize){
                        if(styles[i][0] === fontSize[j].key){
                            sizeFlag = true;
                            editor.setFontSize(fontSize[j].viewText.replace('px',''));
                            break;
                        }
                    }
                }
                if(!styleFlag){
                    editor.setFontStyle(defaultFont);
                }
                if(!sizeFlag){
                    editor.setFontSize(defaultFontSize);
                }
            }
            else{
                editor.setFontStyle(defaultFont);
                editor.setFontSize(defaultFontSize);
            }
            editor.setFontInlineStyle(getSelectionInlineStyle(editor.editorState));
        }
    }, 20);//pc에서는 0으로 해도 문제없었는데 IOS에서는 20으로 해야 제대로 포커스됨. 
}