import React from 'react'
import Viewer from '../components/viewer/Viewer'

function ViewerTemplate({urlParam}) {
    return (
        <Viewer src={urlParam.src}/>
    )
}

export default ViewerTemplate
