import { root_path } from "../../../../common/constants"; 
import { getSelectionInlineStyle } from 'draftjs-utils';

const menus1 = {
    left: [
        {
            font: true,
            icon: null,
            text: '나눔고딕',
            onClick: (onAction) => {
                // alert("test")
            },
        },
        {
            fontsize: true,
            icon: null,
            text: '15',
            onClick: (onAction) => {
                // onAction({ type: 'font', action: 'SIZE_10PX' });
            },
        },
        {
            icon: root_path + '/icon/editor/ic_editor_align_center@2x.png',
            onClick: (onAction) => {
                onAction({ type: 'align', action: {}});
            },
        },
        {
            name: 'BOLD',
            icon: root_path + '/icon/editor/ic_editor_bold@2x.png',
            activeIcon: root_path + '/icon/editor/ic_editor_bold_press@2x.png',
            onClick: (onAction,editor) => {
                const styles = editor.fontInlineStyle == null ? getSelectionInlineStyle(editor.editorState) : editor.fontInlineStyle;
                styles.BOLD ? 
                editor.setFontInlineStyle(Object.assign(styles, {BOLD : false})) : 
                editor.setFontInlineStyle(Object.assign(styles, {BOLD : true}));
                onAction({ type: 'font', action: 'BOLD' });
            },
        },
        {
            name: 'ITALIC',
            icon: root_path + '/icon/editor/ic_editor_italic@2x.png',
            activeIcon: root_path + '/icon/editor/ic_editor_italic_press@2x.png',
            onClick: (onAction,editor) => {
                const styles = editor.fontInlineStyle == null ? getSelectionInlineStyle(editor.editorState) : editor.fontInlineStyle;
                styles.ITALIC ? 
                editor.setFontInlineStyle(Object.assign(styles, {ITALIC : false})) : 
                editor.setFontInlineStyle(Object.assign(styles, {ITALIC : true}));
                onAction({ type: 'font', action: 'ITALIC' });
            },
        },
        {
            name: 'STRIKETHROUGH',
            icon: root_path + '/icon/editor/ic_editor_strikethrough@2x.png',
            activeIcon: root_path + '/icon/editor/ic_editor_strikethrough_press@2x.png',
            onClick: (onAction,editor) => {
                const styles = editor.fontInlineStyle == null ? getSelectionInlineStyle(editor.editorState) : editor.fontInlineStyle;
                styles.STRIKETHROUGH ? 
                editor.setFontInlineStyle(Object.assign(styles, {STRIKETHROUGH : false})) : 
                editor.setFontInlineStyle(Object.assign(styles, {STRIKETHROUGH : true}));
                onAction({ type: 'font', action: 'STRIKETHROUGH' });
            },
        },
        {
            // custom: 'link'
            // custom: 'openGraph'
            icon: root_path + '/icon/editor/ic_editor_link@2x.png',
            onClick: (onAction) => {
                window.parent.postMessage({ name: 'editor', action: 'openGraph' }, '*');
            },
        }
    ],
    right: [
        {
            name: '휴지통',
            icon: root_path + '/icon/editor/ic_room_trash@2x.png',
            onClick: (onAction) => {
                onAction({ type: 'delete', action: {} });
            },
        },
    ],
};

const menus2 = {
    left: [
        {
            name: '수직인용',
            icon: root_path + '/icon/editor/twotone-closed_caption1-24px@2x.png',
            onClick: (onAction) => {
                onAction({ type: 'quote', action: 'QUOTE_VERTICAL' });
            },
        },
        {
            name: '말풍선',
            icon: root_path + '/icon/editor/twotone-closed_caption2-24px@2x.png',
            onClick: (onAction) => {
                onAction({ type: 'quote', action: 'QUOTE_BALLOON' });
            },
        },
        {
            name: '더블',
            icon: root_path + '/icon/editor/twotone-closed_caption-24px@2x.png',
            onClick: (onAction) => {
                onAction({ type: 'quote', action: 'QUOTE_DOUBLE' });
            },
        },
        {
            name: '메모',
            icon: root_path + '/icon/editor/twotone-closed_caption3-24px@2x.png',
            onClick: (onAction) => {
                onAction({ type: 'quote', action: 'QUOTE_MEMO' });
            },
        },
    ],
    right: [
        // {
        //     fontsize: true,
        //     icon: null,
        //     text: '15',
        //     onClick: (onAction) => {
        //         onAction();
        //     },
        // },
        {
            name: '휴지통',
            icon: root_path + '/icon/editor/ic_room_trash@2x.png',
            onClick: (onAction) => {
                onAction({ type: 'delete', action: {} });
            },
        },
    ],
};
const menus3 = {
    left: [
        // {
        //     name: '위치',
        //     icon: root_path + '/icon/editor/outline-place-24px@2x.png',
        //     onClick: (onAction) => {
        //         //dispatch(fullDialogVisible('setLocation'));
        //         window.parent.postMessage({ name: 'editor', action: 'location' }, '*');
        //     },
        // },
        {
            icon: root_path + '/icon/editor/baseline-alternate_email-24px@2x.png',
            onClick: (onAction) => {
                window.parent.postMessage({ name: 'editor', action: 'mention' }, '*');
            },
        },
        {
            name: '콘텐츠',
            icon: root_path + '/icon/editor/baseline-search-24px@2x.png',
            onClick: (onAction) => {
                //dispatch(fullDialogVisible('setContents'));
                window.parent.postMessage({ name: 'editor', action: 'newsSearch' }, '*');
            },
        },
        {
            icon: root_path + '/icon/editor/baseline-bookmark_border-24px@2x.png',
            onClick: () => {
                // alert('북마크 이동 후 삽입');
                window.parent.postMessage({ name: 'editor', action: 'bookmark' }, '*');
            },
        },
    ],
    right: [
        {
            name: '휴지통',
            icon: root_path + '/icon/editor/ic_room_trash@2x.png',
            onClick: (onAction) => {
                // alert('삭제');
                onAction({ type: 'delete', action: {} });
            },
        },
    ],
};
const menus4 = {
    left: [
        {
            icon: root_path + '/icon/editor/baseline-format_list_numbered-24px@2x.png',
            onClick: (onAction) => {
                onAction({ type: 'list', action: 'OL' });
            },
        },
        {
            icon: root_path + '/icon/editor/ic_editor_bulleted@2x.png',
            onClick: (onAction) => {
                onAction({ type: 'list', action: 'UL' });
            },
        },
        {
            icon: root_path + '/icon/editor/baseline-format_list_bulleted-24px@2x.png',
            onClick: (onAction) => {
                onAction({ type: 'list', action: 'UL_SQUARE' });
            },
        },
        // {
        //     name: '볼드',
        //     icon: root_path + '/icon/editor/ic_editor_bold@2x.png',
        //     onClick: (onAction) => {
        //         onAction({ type: 'font', action: 'BOLD' });
        //     },
        // },
        // {
        //     name: '이태릭',
        //     icon: root_path + '/icon/editor/ic_editor_italic@2x.png',
        //     onClick: (onAction) => {
        //         onAction({ type: 'font', action: 'ITALIC' });
        //     },
        // },
        // {
        //     name: '취소선',
        //     icon: root_path + '/icon/editor/ic_editor_strikethrough@2x.png',
        //     onClick: (onAction) => {
        //         onAction({ type: 'font', action: 'STRIKETHROUGH' });
        //     },
        // },
        // {
        //     // custom: 'link'
        //     custom: 'openGraph'
        // }
    ],
    right: [
        {
            name: '휴지통',
            icon: root_path + '/icon/editor/ic_room_trash@2x.png',
            onClick: (onAction) => {
                // alert('삭제');
                onAction({ type: 'delete', action: {} });
            },
        },
    ],
};
const menus5 = {
    left: [
        // {
        //     icon: root_path + '/icon/editor/ic_editor_camera@2x.png',
        //     onClick: (onAction) => {
        //         window.parent.postMessage({ name: 'editor', action: 'camera' }, '*');
        //     },
        // },
        {
            icon: root_path + '/icon/editor/baseline-crop_original-24px@2x.png',
            onClick: (onAction, editor) => {
                // if(editor.isApp){
                //     window.parent.postMessage({ name: 'editor', action: 'gallery' }, '*');
                // }
                // else{
                    document.getElementById('imageUploader').click();
                // }
            },
        },
        // {
        //     icon: root_path + '/icon/editor/outline-play_circle_filled_white-24px@2x.png',
        //     onClick: (onAction) => {
        //         alert('영상 URL만 입력가능 개발중');
        //     },
        // },
        {
            // custom: 'video'
            icon: root_path + '/icon/editor/outline-play_circle_filled_white-24px@2x.png',
            onClick: (onAction) => {
                window.parent.postMessage({ name: 'editor', action: 'urlLink' }, '*');
            },
        },
    ],
    right: [
        {
            name: '휴지통',
            icon: root_path + '/icon/editor/ic_room_trash@2x.png',
            onClick: (onAction) => {
                // alert('삭제');
                onAction({ type: 'delete', action: {} });
            },
        },
    ],
};

// const arr = [menus1, menus2, menus4, menus5 , /*menus3*/];
const arr = [menus1, menus2, menus3, menus4, menus5];
export default arr;
