import { convertFromRaw, convertToRaw, EditorState, Modifier, SelectionState } from "draft-js";
import { InsertAtomic } from "../atomic/AtomicFunction";
import { blockTypeChange, fontChange, linkTypeChange} from "../func/FontFunction";
import { HASHTAG_REGEX } from '../strategy/hashTag/HashTagStrategy'
import { MENTION_REGEX } from '../strategy/mention/MentionStrategy'
import { pastedText } from "../func/handler";
import { getSelectedBlock, insertNewUnstyledBlock } from 'draftjs-utils';
import { isPC, isAndroid, isAppleWeb, isAppleApp, getOG } from "../../common/interface/common";
const initInlineStyle = (editor) => {
    const selection = editor.editorState.getSelection();
    if(selection.focusOffset === selection.anchorOffset && selection.focusKey === selection.anchorKey){
        if(isAndroid()){//모바일 안드로이드
            new Promise((resolve, reject) => {
                const doc = document.activeElement;//자동완성을 풀어줘야함
                doc.blur();
                doc.focus();
                resolve();
            })
            .then((e) => {
                const isAuto = localStorage.getItem('is-autocomplete');
                if (isAuto === 'auto') {//자동완성
                    const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
                    wait(0).then(() => {
                        var offset = selection.getAnchorOffset();
                        var text = getSelectedBlock(editor.editorState).getText();
                        var charBehind = text[offset - 1];
                        let contentState = editor.editorState.getCurrentContent();
                        const sel = editor.editorState.getSelection();
                        if (charBehind === '​') {
                            contentState = Modifier.replaceText(contentState, selection, '​');
                            editor.setEditorState(EditorState.push(editor.editorState, contentState, 'insert-characters'));//zero space 생성
                            contentState = Modifier.removeRange(
                                contentState,
                                new SelectionState({
                                    anchorKey: sel.anchorKey,
                                    focusKey: sel.focusKey,
                                    anchorOffset: sel.anchorOffset - 1,
                                    focusOffset: sel.focusOffset - 0,
                                    hasFocus: false
                                }),
                                "backward"
                            );
                            const newEditorState = EditorState.push(editor.editorState, contentState, 'backspace-character');//이전 zero space는 삭제
                            editor.setEditorState(EditorState.forceSelection(newEditorState, new SelectionState({
                                anchorKey: sel.anchorKey,
                                focusKey: sel.focusKey,
                                anchorOffset: sel.anchorOffset - 0,
                                focusOffset: sel.focusOffset - 0,
                                hasFocus: false
                            })));//제자리로 focus
                        }
                        else{
                            const sel = editor.editorState.getSelection();
                            let contentState = editor.editorState.getCurrentContent();
                            contentState = Modifier.replaceText(contentState, selection, '​');
                            const newEditorState = EditorState.push(editor.editorState, contentState, 'insert-character');
                            editor.setEditorState(EditorState.forceSelection(newEditorState, new SelectionState({
                                anchorKey: sel.anchorKey,
                                focusKey: sel.focusKey,
                                anchorOffset: sel.anchorOffset + 1,
                                focusOffset: sel.focusOffset + 1,
                                hasFocus: false
                            })));//제자리로 focus
                        }
                    });
                }
                else {
                    const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
                    wait(0).then(() => {
                        let contentState = editor.editorState.getCurrentContent();
                        contentState = Modifier.replaceText(contentState, selection, '​');
                        editor.setEditorState(EditorState.push(editor.editorState, contentState, 'insert-characters'));//zero space 생성
                        var offset = selection.getAnchorOffset();
                        var text = getSelectedBlock(editor.editorState).getText();
                        var charBehind = text[offset - 1];
                        const sel = editor.editorState.getSelection();
                        if (charBehind === '​') {
                            contentState = Modifier.removeRange(
                                contentState,
                                new SelectionState({
                                    anchorKey: sel.anchorKey,
                                    focusKey: sel.focusKey,
                                    anchorOffset: sel.anchorOffset - 2,
                                    focusOffset: sel.focusOffset - 1,
                                    hasFocus: false
                                }),
                                "backward"
                            );
                            const newEditorState = EditorState.push(editor.editorState, contentState, 'backspace-character');//이전 zero space는 삭제
                            editor.setEditorState(EditorState.forceSelection(newEditorState, new SelectionState({
                                anchorKey: sel.anchorKey,
                                focusKey: sel.focusKey,
                                anchorOffset: sel.anchorOffset - 1,
                                focusOffset: sel.focusOffset - 1,
                                hasFocus: false
                            })));//제자리로 focus
                        }
                    });
                }
            });
        }
        else if(isPC()){//pc, mac
            const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
            wait(0).then(() => {
                let contentState = editor.editorState.getCurrentContent();
                contentState = Modifier.replaceText(contentState, selection, '​');
                var offset = selection.getAnchorOffset();
                editor.setEditorState(EditorState.push(editor.editorState, contentState, 'insert-characters'));//zero space 생성
                var text = getSelectedBlock(editor.editorState).getText();
                var charBehind = text[offset - 1];
                const sel = editor.editorState.getSelection();
                if(charBehind === '​'){
                    contentState = Modifier.removeRange(
                        contentState,
                        new SelectionState({
                            anchorKey: sel.anchorKey,
                            focusKey: sel.focusKey,
                            anchorOffset: sel.anchorOffset-2,
                            focusOffset: sel.focusOffset-1,
                            hasFocus: false
                        }),
                        "backward"
                    );
                    const newEditorState = EditorState.push(editor.editorState, contentState, 'backspace-character');//이전 zero space는 삭제
                    editor.setEditorState(EditorState.forceSelection(newEditorState, new SelectionState({
                        anchorKey: sel.anchorKey,
                        focusKey: sel.focusKey,
                        anchorOffset: sel.anchorOffset-1,
                        focusOffset: sel.focusOffset-1,
                        hasFocus: false
                    })));//제자리로 focus
                }
            });
        }
        
        else if (isAppleWeb() || isAppleApp()) {
            if (!editor.isPC) {//모바일 IOS
                const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
                wait(0).then(() => {
                    let contentState = editor.editorState.getCurrentContent();
                    contentState = Modifier.replaceText(contentState, selection, '​');
                    editor.setEditorState(EditorState.push(editor.editorState, contentState, 'insert-characters'));//zero space 생성
                    var offset = selection.getAnchorOffset();
                    var text = getSelectedBlock(editor.editorState).getText();
                    var charBehind = text[offset - 1];
                    const sel = editor.editorState.getSelection();
                    if(charBehind === '​'){
                        contentState = Modifier.removeRange(
                            contentState,
                            new SelectionState({
                                anchorKey: sel.anchorKey,
                                focusKey: sel.focusKey,
                                anchorOffset: sel.anchorOffset-2,
                                focusOffset: sel.focusOffset-1,
                                hasFocus: false
                            }),
                            "backward"
                        );
                        setTimeout(() => {//비동기 처리
                            const newEditorState = EditorState.push(editor.editorState, contentState, 'backspace-character');//이전 zero space는 삭제
                            editor.setEditorState(EditorState.forceSelection(newEditorState, new SelectionState({
                                anchorKey: sel.anchorKey,
                                focusKey: sel.focusKey,
                                anchorOffset: sel.anchorOffset-1,
                                focusOffset: sel.focusOffset-1,
                                hasFocus: false
                            })));//제자리로 focus
                        },0);
                    }

                })
                .then((e) => {
                    const doc = document.activeElement;//자동완성을 풀어줘야함
                    const init = document.querySelector('#memoryInit');
                    init.focus();
                    doc.focus();
                    let selection4 = new SelectionState({//중간에 글 삽입시 focus 처리
                        anchorKey: selection.anchorKey,
                        focusKey: selection.focusKey,
                        anchorOffset: selection.focusOffset + 1,
                        focusOffset: selection.focusOffset + 1,
                        hasFocus: true,
                        isBackward: false
                    });
                    editor.setEditorState(EditorState.forceSelection(editor.editorState, selection4));
                });
            }
        }
        if(selection.focusOffset === selection.anchorOffset){
            let contentState = editor.editorState.getCurrentContent();
            contentState = Modifier.insertText(contentState, editor.editorState.getSelection(), '');
            editor.setEditorState(EditorState.push(editor.editorState, contentState, 'insert-characters'));
        }
    }
}

export const Inteface = (arg, editor, news) => {
    if (arg.data){
        const { type, param } = arg.data
        if(type){ 
            switch(type){
                case 'SET' : 
                    if(param){
                        if(param.type !== 'data'){//본문변경시 플래그값을 프론트앱에 전달.
                            window.parent.postMessage({ name: 'editor', action: 'changed', value: true }, '*');
                        }
                        switch(param.type){
                            case 'font' :
                                if (!editor.readOnly) {
                                    switch (param.action) {
                                        case 'SIZE': {
                                            const isAuto = localStorage.getItem('is-autocomplete');
                                            initInlineStyle(editor);
                                            if (isAuto === 'auto') {
                                                setTimeout(() => {//비동기로 호출안하면 라인 전체가 스타일이 적용됨
                                                    fontChange(editor.setEditorState, editor.editorState, param.size);
                                                }, 0);
                                            }
                                            else{
                                                fontChange(editor.setEditorState, editor.editorState, param.size);
                                            }
                                            break;
                                        }
                                        default: {
                                            const isAuto = localStorage.getItem('is-autocomplete');
                                            initInlineStyle(editor);
                                            if (isAuto === 'auto') {//비동기로 호출안하면 라인 전체가 스타일이 적용됨
                                                setTimeout(() => {
                                                    fontChange(editor.setEditorState, editor.editorState, param.action);
                                                }, 0);
                                            }
                                            else{
                                                fontChange(editor.setEditorState, editor.editorState, param.action);
                                            }
                                            break;
                                        }
                                    }
                                }
                                break;
                            case 'news' :
                                if (editor.toolbar) {
                                    InsertAtomic(editor, 'news', 'IMMUTABLE', param.data);
                                }
                                break;
                            case 'repost' :
                                if (editor.toolbar) {
                                    InsertAtomic(editor, 'repost', 'IMMUTABLE', param.data);
                                    // InsertAtomic(editor, 'repost', 'IMMUTABLE', { text:param.action, split:false });
                                }
                                break;
                            case 'newsLink':
                                if (editor.toolbar) {
                                    InsertAtomic(editor, 'newsLink', 'IMMUTABLE', param.data);
                                }
                                break;
                            case 'list' :
                                if (!editor.readOnly) {
                                    const style = param.action === 'UL' ? 'unordered-list-item' : param.action === 'OL'?'ordered-list-item' : param.action;
                                    blockTypeChange(editor.setEditorState, editor.editorState, style);
                                }
                                break;
                            case 'link' :
                                linkTypeChange(editor.setEditorState, editor.editorState, param.action);
                                break;    
                            case 'quote' :
                                blockTypeChange(editor.setEditorState, editor.editorState, param.action);
                                break;
                            case 'align' :
                                var selection = editor.editorState.getSelection();
                                var contents = editor.editorState.getCurrentContent();
                                var selectedBlock = contents.getBlockForKey(selection.getStartKey());
                                var blockType = selectedBlock.type;
                                blockTypeChange(editor.setEditorState, editor.editorState, blockType == 'TEXT_LEFT' || blockType == 'unstyled' ? 'TEXT_CENTER' : (blockType == 'TEXT_CENTER' ? 'TEXT_RIGHT' : 'TEXT_LEFT'));
                                break;        
                            case 'image' :
                                InsertAtomic(editor, 'image', 'IMMUTABLE', { src:param.action});
                                break;    
                            case 'slider' :
                                InsertAtomic(editor, 'slider', 'IMMUTABLE', { src:param.action});
                                break;
                            case 'collage' :
                                InsertAtomic(editor, 'collage', 'IMMUTABLE', { src:param.action});
                                break;
                            case 'textarea' :
                                InsertAtomic(editor, 'textarea', 'IMMUTABLE', { text:param.action, split:false });
                                break;
                            case 'video' :
                                pastedText(param.data.url, editor);
                                // InsertAtomic(editor, 'textarea', 'IMMUTABLE', { text:'', split:false });
                                break;      
                            case 'openGraph' :
                                if(param.data.url != null && param.data.url !== ''){
                                    getOG(param.data.url).then(function (response) {
                                        editor.setEditorState(insertNewUnstyledBlock(editor.editorState));
                                        if (response) {
                                            InsertAtomic(editor, 'openGraph', 'IMMUTABLE', {
                                                src: response.ogImage == null ? null : response.ogImage.url,
                                                title: response.ogTitle,
                                                description: response.ogDescription,
                                                url: response.requestUrl,
                                                split: false
                                            });
                                        }
                                        else {
                                            InsertAtomic(editor, 'openGraph', 'IMMUTABLE', {
                                                src: '/icon/editor/bg_og_error.png',
                                                title: '유효하지 않은 링크 입니다.',
                                                description: '유효하지 않은 링크 입니다.',
                                                url: param.data.url,
                                                split: false
                                            });
                                        }
                                    });
                                }
                                // InsertAtomic(editor, 'openGraph', 'IMMUTABLE', { 
                                //     src: param.action.ogImage, 
                                //     title: param.action.ogTitle, 
                                //     description: param.action.ogDescription,
                                //     url: param.action.ogUrl,
                                //     split: false
                                // });
                                break;
                            case 'delete' :
                                var selection = editor.editorState.getSelection();
                                var contents = editor.editorState.getCurrentContent();
                                var selectedBlock = contents.getBlockForKey(selection.getStartKey());
                                var beforeBlock = contents.getBlockBefore(selection.getStartKey());

                                if(selectedBlock.getEntityAt(0) !== null){//리포스트 삭제하려할때 예외처리
                                    const type = contents.getEntity(selectedBlock.getEntityAt(0)).getType();
                                    if (type === 'repost') {
                                        window.parent.postMessage({ name: 'editor', action: 'alert', text: '리포스트 영역은 삭제할 수 없습니다.' }, '*');
                                        const doc = document.activeElement;//자동완성을 풀어줘야함
                                        doc.blur();
                                        return false;
                                    }
                                }

                                let removeSelection = new SelectionState({
                                    anchorKey: beforeBlock != null ? beforeBlock.key : selectedBlock.key,
                                    anchorOffset: beforeBlock != null ? beforeBlock.text.length : 0,
                                    focusKey: selectedBlock.key,
                                    focusOffset: selectedBlock.text.length
                                });
                                let newContentState = Modifier.removeRange(
                                    contents,
                                    removeSelection,
                                    "backward"
                                );
                                if(beforeBlock == null){//첫줄일때 블록 스타일도 제거
                                    const key = selectedBlock.getKey();
                                    let contentState = editor.editorState.getCurrentContent();
                                    const targetRange = new SelectionState({
                                        anchorKey: key,
                                        anchorOffset: 0,
                                        focusKey: key,
                                        focusOffset: selectedBlock.getLength()
                                    });
                                    let newContentState = Modifier.removeRange(
                                        contentState,
                                        targetRange,
                                        "backward"
                                    );
                                    var resetBlock = Modifier.setBlockType(
                                        newContentState,
                                        newContentState.getSelectionAfter(),
                                        'unstyled'
                                    );
                                    const newEditorState = EditorState.push(editor.editorState, resetBlock, 'remove-range')
                                    editor.setEditorState(newEditorState);//줄 삭제
                                }
                                else{
                                    editor.setEditorState(EditorState.push(editor.editorState, newContentState, 'remove-range'));//줄 삭제
                                }
                                break;
                            case 'data' :
                                editor.setIsLoading(true);
                                if(param.data != null){
                                    const convert = convertFromRaw(param.data);
                                    editor.setEditorStateWithContent(convert);
                                }
                                setTimeout(() => {
                                    editor.setIsLoading(false);
                                    editor.setEditorState(EditorState.moveFocusToEnd(editor.editorState));
                                }, 0);
                                break;
                            case 'mention' :
                                let contentState = editor.editorState.getCurrentContent();
                                contentState = Modifier.replaceText(contentState, editor.editorState.getSelection(), ' 「@' + param.action.name + ':' + param.action.rid + '」');
                                editor.setEditorState(EditorState.push(editor.editorState,contentState,'insert-characters'));

                                // /////////////////////
                                // if(editor.isMobile){//모바일은 textarea 빈값
                                //     const currentText = getTextareaText(editor);
                                //     setTextareaText(editor,(currentText!=null?currentText+' ':'') + '@' + param.action.name);
                                // }
                                // else{
                                //     let contentState = editor.editorState.getCurrentContent();
                                //     contentState = Modifier.replaceText(contentState, editor.editorState.getSelection(), '@' + param.action.name);
                                //     editor.setEditorState(EditorState.push(editor.editorState,contentState,'insert-characters'));
                                // }
                                
                                // let arrMention = JSON.parse(localStorage.getItem('mentionList')) ;
                                // arrMention = arrMention?arrMention:[];

                                // const obj = [
                                //     ...arrMention,
                                //     {
                                //         name : '@'+param.action.name ,
                                //         code : '「@' + param.action.name + ':' + param.action.rid + '」',
                                //     }
                                // ]
                                // localStorage.setItem('mentionList',
                                //     JSON.stringify(obj),
                                // )
                                // console.log(JSON.parse(localStorage.getItem('mentionList')));
                                // /////////////////////
                                break;                 
                            case 'test' :
                                // blockTypeChange(editor.setEditorState, editor.editorState, 'header-one');
                                pastedText('https://www.youtube.com/watch?v=bkqUy01zO4o', editor);
                                break; 
                            case 'save' :
                                localStorage.setItem('CONTENT_TEST', JSON.stringify(convertToRaw(editor.editorState.getCurrentContent())))
                                break;
                            default :
                                break;
                        }
                    }
                    break;
                case 'GET':
                    Sender(editor)
                    break;
                case 'SHOW':
                    news.setOpen(true);
                    break;
                case 'CHECK_DATA':
                    checkData(editor);
                    break;     
                default : 
                    break;
            }
        }
    }

}

const setContents = (editor) => {
    const content = editor.editorState.getCurrentContent();
    const rawData = convertToRaw(content);
    // const plainText = content.getPlainText();
    let plainText = '';
    rawData.blocks.map(e=>{
        if(e.type !== 'atomic'){
            plainText += e.text + '\n'
        }
        else{
            if(e.entityRanges.length > 0){
                if(rawData.entityMap[e.entityRanges[0].key].type === 'textarea'){
                    plainText += rawData.entityMap[e.entityRanges[0].key].data.text + '\n';
                }
            }
        }
    });

    const entityMap = rawData.entityMap;
    var imgList = [];
    var youtubeList = [];
    var videoList = [];
    var newsList = [];
    var repostList = [];
    var linkList = [];
    var sliderList = [];
    var collageList = [];
    var textareaList = [];
    var openGraphList = [];
    if(entityMap){
        const temp = Object.keys(entityMap).map((item,index)=>{
            return entityMap[item]
        });
        imgList = temp.filter(item => item.type === 'image').map(item => item.data.src);
        youtubeList = temp.filter(item => item.type === 'youtube').map(item => item.data.src);
        videoList = temp.filter(item => item.type === 'video').map(item => item.data.src);
        newsList = temp.filter(item => item.type === 'news' || item.type === 'repost').map(item => item.data);
        repostList = temp.filter(item => item.type === 'repost').map(item => item.data);
        linkList = temp.filter(item => item.type === 'link').map(item => item.data.src);
        sliderList = temp.filter(item => item.type === 'slider').map(item => item.data.src);
        collageList = temp.filter(item => item.type === 'collage').map(item => item.data.src);
        textareaList = temp.filter(item => item.type === 'textarea').map(item => item.data.text.replace(/​/g,''));
        textareaList = textareaList.length === 0 ? [plainText.replace(/​/g,'')] : textareaList;//일단 textarea 안비게
        openGraphList = temp.filter(item => item.type === 'openGraph').map(item => item.data);
    }

    const retJSON = { 
        raw: rawData, 
        filter:{
            text: plainText.replace(/​/g,''),
            imgList : imgList,
            newsList: newsList,
            repostList: repostList,
            youtubeList: youtubeList,
            videoList: videoList,
            linkList: linkList,
            sliderList: sliderList,
            collageList: collageList,
            textareaList: textareaList,
            openGraphList: openGraphList,
            hashTag: getHashtag(plainText),
            // mention: getMention(plainText),
            mention: JSON.parse(localStorage.getItem('mentionList')),
        }
    }
    return retJSON;
}

const checkData = ( editor ) => {
    const data = setContents(editor);
    const filter = data.filter;
    if (
        (
            filter.text === '' ||
            filter.text == null ||
            filter.text === '\n'
        ) &&
        filter.imgList.length === 0 &&
        filter.youtubeList.length === 0 &&
        filter.videoList.length === 0 &&
        filter.newsList.length === 0 &&
        filter.repostList.length === 0 &&
        filter.linkList.length === 0 &&
        filter.sliderList.length === 0 &&
        filter.collageList.length === 0 &&
        filter.openGraphList.length === 0
    ) {
        window.parent.postMessage({ name: 'editor', action: 'isNull', value: true }, '*');
    }
    else {
        window.parent.postMessage({ name: 'editor', action: 'isNull', value: false }, '*');
    }
}

const Sender = ( editor ) => {
    const data = setContents(editor);
    window.parent.postMessage(data, '*');
} 

const getHashtag = ( text ) => {
    const temp = text.match(HASHTAG_REGEX);
    const set = new Set(temp);
    const retArr = [...set]
    return retArr;
}

const getMention = (text) => {
    const temp = text.match(MENTION_REGEX);
    const set = new Set(temp);
    const retArr = [...set]
    return retArr;
}