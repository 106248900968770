import { Popper } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { useRef, useState } from 'react';
import { DeleteAtomic, MoveAtomic, getAtomicOffsetTop } from './AtomicFunction';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { root_path } from '../../common/constants';

const VideoAtomic = ({ editor, block, type, src, title }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const item = useRef();

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    
    const moveScroll = () => {
        if(item != null){
            setTimeout(() => {
                const offsetTop = getAtomicOffsetTop(item);
                window.postMessage({ name: 'scrollDown', offsetTop: offsetTop - 100}, '*') //뉴스 아웃링크
            }, 0);
        }
    }
    const moveDownwardAtomic = () => {
        MoveAtomic(editor, block, 'after');
        moveScroll();
    }
    const moveUpwardAtomic = () => {
        MoveAtomic(editor, block, 'before');
        moveScroll();
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
                ref={item}
                onClick={handleClick}
                style={{ position:'relative', resize: 'none', overflow: 'auto', padding: '7px 0px', width: '100%', maxWidth: editor.isPC===true ? '420px' : null}}
            >
                {editor.toolbar && //입력, 수정모드일때는 껍데기만
                    <div style={{ width:'100%', overflowX:'hidden', overflow:'auto',minHeight:'250px', height: '50vw' }}></div>
                }
                {!editor.toolbar && //뷰 모드일때 유툽 로드
                    <iframe 
                        title='YOUTUBE' 
                        style={{ width:'100%', overflowX:'hidden', overflow:'auto',minHeight:'250px', height: '50vw' }} 
                        src={'https://www.youtube.com/embed/'+src}
                        frameBorder="0"
                        scrolling="no"
                        allow="encrypted-media" allowFullScreen>
                    </iframe>
                }
                {
                    <Popper id={id} open={open} anchorEl={anchorEl} placement='top'
                        modifiers={{
                            flip: {enabled: false,},
                            preventOverflow: {enabled: false,},
                            offset: {
                              enabled: true,
                              offset: '0px 20px -50px -20px'
                            }
                        }}
                        style={{background:'white', borderRadius: '5px', zIndex:'9999'}}
                    >
                        <div style={{ cursor: 'pointer' }}>
                            <ArrowUpwardIcon onClick={(e) => moveUpwardAtomic(block, editor)} style={{padding:'5px 5px 6px 5px', marginBottom:'-5px'}} />
                            <ArrowDownwardIcon onClick={(e) => moveDownwardAtomic(block, editor)} style={{padding:'5px 5px 6px 5px', marginBottom:'-5px'}} />
                            <DeleteForeverOutlinedIcon onClick={(e) => DeleteAtomic(block, editor)} style={{padding:'5px 5px 6px 5px', marginBottom:'-5px'}} />
                        </div>
                    </Popper>
                }
                {editor.toolbar &&
                    <React.Fragment>
                    {
                        // <img src={root_path + '/images/transparentImg.png'} style={{ position: 'absolute', top: 0, left:0, width: '100%', height: '100%' }} alt='___' />
                    }
                        <div style={{position: 'absolute', color: 'white', top: '0px', zIndex: '999', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: 'calc(100% - 20px)', padding: '10px'}}>{title}</div>
                        <img src={`https://img.youtube.com/vi/${src}/0.jpg`} style={{ position: 'absolute', top: 0, left:0, width: '100%', height: '100%' }} alt='___' />
                        <img src='/images/logos_youtube.png' style={{ position: 'absolute', top: 'calc(50% - 21px)', left:'calc(50% - 30px)', width: '60px', height: '42px' }} alt='___' />
                        
                    </React.Fragment>
                }
            </div>
        </div>
    )
}

export default inject(({ editor }) => ({
    editor: editor,
}))(observer(VideoAtomic));