import { observer, inject } from 'mobx-react';
import React from 'react';

const styles = {
    mention: {
        color: "rgba(98, 177, 254, 1.0)",
        direction: "ltr",
        unicodeBidi: "bidi-override",
        cursor: 'pointer'
    }
};
const MentionComponent = (props) => {
    const vanillaText = props.decoratedText;
    const decoratedText = vanillaText.substring(1,vanillaText.length-1);
    const text = decoratedText.substring(0,decoratedText.indexOf(':'));
    const rid = decoratedText.substring(decoratedText.lastIndexOf(':')+1,decoratedText.length);
    const handleClick = (e) => {
        window.parent.postMessage({ name: 'new_window', action:'transform', type: 'mention', seq: rid}, '*');
    }
    return (
        props.editor.toolbar ?//에디터모드일때는 변환하지않고 코드 노출
        <span style={styles.mention} data-offset-key={props.offsetKey} onClick={handleClick}>
            <span data-text="true">
                {vanillaText}
            </span>
        </span>
        ://뷰 모드일때는 변환
        <span style={styles.mention} data-offset-key={props.offsetKey} onClick={handleClick}>
            <span data-text="true">
                {text}
            </span>
        </span>
    );
};

// export default MentionComponent;
export default inject(({ editor }) => ({
    editor: editor,
}))(observer(MentionComponent));
