import React, { useState } from 'react';
import styled from 'styled-components';
// import { ReactComponent as Close } from 'asset/icon9/ic_closed.svg';
// import theme from 'theme';
import { observer,inject } from 'mobx-react';

const MenuBox = styled.div`
    display: ${({ show }) => (show ? 'flex' : 'none')};
    position: absolute;
    left: 0;
    top: 49px;
    width: 73.5px;
    box-sizing: border-box;
    background-color: white;
    z-index: 3;
    border-left: 1px solid #e2e2e2;
    border-right: 1px solid #e2e2e2;
    ul {
        width: 100%;
        display: flex;
        flex-direction: column;
        li {
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #e2e2e2;
            img {
                display: block;
                height: 24px;
                width: auto;
            }
        }
    }
`;


const MenuBoxApp = styled.div`
    display: ${({ show }) => (show ? 'flex' : 'none')};
    position: absolute;
    left: 0;
    top: -196px;
    width: 73.5px;
    box-sizing: border-box;
    background-color: white;
    z-index: 3;
    border-left: 1px solid #e2e2e2;
    border-right: 1px solid #e2e2e2;
    ul {
        width: 100%;
        display: flex;
        flex-direction: column;
        li {
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top: 1px solid #e2e2e2;
            img {
                display: block;
                height: 24px;
                width: auto;
            }
        }
    }
`;


const DropdownBlock = styled.div`
    position: relative;
    svg {
        opacity: 0.5;
    }
`;

const EditDropdown = ({ children, show, onShow, editor }) => {
    return (
        <DropdownBlock onClick={onShow}>
            {children.slice(0, 1)}
            {
                // editor.isApp?
                // <MenuBoxApp show={show}>{children.slice(1)}</MenuBoxApp>
                // :
                <MenuBox show={show}>{children.slice(1)}</MenuBox>
            }
        </DropdownBlock>
    );
};

export default inject(({ editor }) => ({
    editor: editor
}))(observer(EditDropdown));
