import { Popper } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { useRef, useState } from 'react';
import { DeleteAtomic, getAtomicPosition, MoveAtomic, MoveAtomic2, getAtomicOffsetTop } from './AtomicFunction';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const ImageAtomic = ({ editor, block, type, src }) => {
    const [align, setAlign] = useState('center');
    const [imgSize, setImgSize] = useState({ width: '100%', height: 'auto' });
    const [anchorEl, setAnchorEl] = useState(null);
    const item = useRef();

    const handleClick = (event) => {
        if(editor.toolbar){
            setAnchorEl(anchorEl ? null : event.currentTarget);
        }
        else{
            if(editor.isApp){
                const viewerPath = window.location.origin + '?viewer=true&src='+src;
                // window.parent.postMessage({name : 'Viewer' , value : viewerPath}, '*');
                // window.parent.postMessage({name : 'DEFAULT' , value : viewerPath}, '*');
                window.parent.postMessage({name : 'DEFAULT' , value : src}, '*');
            }
            else{
                window.open(`/?viewer=true&src=${src}`);
            }
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const handleImageLoad = (e) => {
        // setImgSize({ width: e.target.width + 'px', height: e.target.height + 'px', maxHeight: e.target.height + 'px' })
    }

    const moveScroll = () => {
        if(item != null){
            setTimeout(() => {
                const offsetTop = getAtomicOffsetTop(item);
                console.log(offsetTop)
                window.postMessage({ name: 'scrollDown', offsetTop: offsetTop - 100}, '*') //뉴스 아웃링크
            }, 0);
        }
    }
    const moveDownwardAtomic = () => {
        MoveAtomic(editor, block, 'after');
        moveScroll();
    }
    const moveUpwardAtomic = () => {
        MoveAtomic(editor, block, 'before');
        moveScroll();
    }
    const handleDragStart =() => {
        const targetKey = editor.editorState.getSelection().focusKey;
        console.log("start", targetKey)
    }
    const handleDragEnd =() => {
        const targetKey = editor.editorState.getSelection().focusKey;
        console.log("stop" , block.key , targetKey)
        MoveAtomic2(editor, block, targetKey);
    }
    const handleOver =() => {
        const targetKey = editor.editorState.getSelection().focusKey;
        console.log(targetKey)
    }
    return (
        <div style={{display:'flex', justifyContent: align }}>
            <div 
                onClick={handleClick} 
                // onDragEnd={handleDragEnd}
                // onDragOver={handleOver}
                style={{ resize: 'none', overflow: (!editor.readOnly ? 'auto' : 'none'), width: imgSize.width, height: imgSize.height, padding: '7px 0px', maxWidth: editor.isPC===true ? '420px' : null }}
                ref={item}
            >
                <img 
                    src={src} 
                    style={{ 
                        width: '100%',
                        display: 'block',
                        margin: 'auto',
                        maxWidth: '700px'
                    }} 
                    alt={type} 
                    onLoad={handleImageLoad}
                />
                {
                    // <Popper id={id} open={open} anchorEl={anchorEl} placement='top'>
                    //     <div style={{ cursor: 'pointer' }}>
                    //         <FormatAlignLeftIcon onClick={(e) => setAlign('flex-start')} />
                    //         <FormatAlignCenterIcon onClick={(e) => setAlign('center')} />
                    //         <FormatAlignRightIcon onClick={(e) => setAlign('flex-end')} />
                    //     </div>
                    // </Popper>
                    <Popper id={id} open={open} anchorEl={anchorEl} placement='top'
                        modifiers={{
                            flip: {enabled: false},
                            // preventOverflow: {enabled: false,},
                            offset: {
                              enabled: true,
                              offset: '0px 20px -50px -20px'
                            }
                        }}
                        style={{background:'white', borderRadius: '5px'}}
                    >
                        <div style={{ cursor: 'pointer' }}>
                            <ArrowUpwardIcon onClick={(e) => moveUpwardAtomic(block, editor)} style={{padding:'5px 5px 6px 5px', marginBottom:'-5px'}} />
                            <ArrowDownwardIcon onClick={(e) => moveDownwardAtomic(block, editor)} style={{padding:'5px 5px 6px 5px', marginBottom:'-5px'}} />
                            <DeleteForeverOutlinedIcon onClick={(e) => DeleteAtomic(block, editor)} style={{padding:'5px 5px 6px 5px', marginBottom:'-5px'}} />
                        </div>
                    </Popper>
                }
            </div>
        </div>
    )
}

export default inject(({ editor }) => ({
    editor: editor,
}))(observer(ImageAtomic));
