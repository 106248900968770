import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react'
import ViewerTemplate from '../templates/ViewerTemplate'

function get_query() {
    var url = document.location.href;
    var qs = url.substring(url.indexOf('?') + 1).split('&');
    for (var i = 0, result = {}; i < qs.length; i++) {
        qs[i] = qs[i].split('=');
        result[qs[i][0]] = decodeURIComponent(qs[i][1]);
    }
    return result;
}

function ViewerContainer({editor}) {
    const urlParam = get_query();
    
    // PC, MOBILE 구별
    function deviceCheck() {
        var pcDevice = "win16|win32|win64|mac|macintel";// 디바이스 종류 설정
        if ( navigator.platform ) {// 접속한 디바이스 환경
            if ( pcDevice.indexOf(navigator.platform.toLowerCase()) < 0 ) {
                editor.setIsPC(false);
                editor.setIsApp(urlParam.isApp==="true"?true:false);
            } else {
                editor.setIsApp(false);
                editor.setIsPC(true);
            }
        }
    }
    useEffect(() => {
        deviceCheck();
    }, []);
    return (
        <ViewerTemplate urlParam={urlParam}/>
    )
}

export default inject(({ editor }) => ({
    editor: editor
}))(observer(ViewerContainer));
