import axios from 'axios';

import { news_server_addr, server_addr } from '../constants';

export const isiPhone = () => {
    const navi = navigator.userAgent;
    return navi.indexOf("iPhone") === -1 ? false : true;
}

export const isiPad = () => {
    const navi = navigator.userAgent;
    return navi.indexOf("iPad") === -1 ? false : true;
}

export const isAppleWeb = () => {
    const navi = navigator.userAgent;
    return navi.indexOf("Safari") === -1 ? false : true;
}

export const isAndroid = () => {
    const navi = navigator.userAgent;
    return navi.indexOf("Android") === -1 ? false : true;
}

export const isWindows = () => {
    const navi = navigator.userAgent;
    return navi.indexOf("Windows") === -1 ? false : true;
}

export const isMacOS = () => {
    const navi = navigator.userAgent;
    return navi.indexOf("Macintosh") === -1 ? false : true;
}

export const isAppleApp = () => {
    return isiPhone() || isiPad() ? true : false;
}

export const isPC = () => {
    return isWindows() || isMacOS() ? true : false;
}


export const getOG = async ( url ) => {

    const path = '/editor/open-graph/info'

    var param = { url: "" };
    if (url) {
        param = {
            url: url,
        }
    }
    const resultList = await axios.post(server_addr + path, param, null)
            .then((response) => {
                return response.data;
            }).catch(err => {
                return null;
            });

    return resultList;
}

// export const getBreakMoreNews = async ({params}) => {
//     //const path = '/main/flash/0/' + breakNews.pageNum + '/' + breakNews.size
//     const path = '/news';
//     const resultList = await axios.get(news_server_addr + path, params, {})
//         .then((response) => {
//             console.log(response)
//             return response;
//         }).catch(err => {
//             return null;
//         });
//     return resultList;
// }
export const getBreakMoreNews = ({params}) => {
    //const path = '/main/flash/0/' + breakNews.pageNum + '/' + breakNews.size
    const path = news_server_addr+'/news';
    return axios({
        method:'get',
        baseURL: path,
        params: params
    });    
}

export const getResizeInfo = (url, width, height) => {

    const img = new Image();
    img.src = url
    var size = '120px 120px';
    var position = 'center center';
    var resizeWidth = 0;
    // var resizeHeight = 0;

    var targetWidth = window.innerWidth * width;

    // var targetHeight = height;
    var resizeVal = 0;
    // var resizeFlag = false;
    resizeVal = targetWidth - img.width;
    resizeWidth = img.width + resizeVal;
    // resizeHeight = img.height + (resizeVal * img.height / img.width);
    // if (resizeHeight < targetHeight) {
    //     resizeVal = targetHeight - resizeHeight;
    //     //console.log("@@@@@@@@@@@@@@@@@@", resizeHeight, targetHeight, resizeVal, resizeWidth, resizeWidth + resizeVal)
    //     resizeWidth = resizeWidth + (resizeVal * ((img.width / img.height) + 1));
    // } else {
    //     if ((targetHeight * 2) < resizeHeight) {
    //         position = 'center -30px'
    //     } else if ((targetHeight + 50) < resizeHeight) {
    //         position = 'center -10px'
    //     } else if ((targetHeight + 5) < resizeHeight) {
    //         position = 'center top'
    //     }
    // }
    // console.log(position)
    size = resizeWidth + 'px auto'

    if (img.width > img.height) {
        // if(ratio <= 1.6){
        //     if(ratio<=1.6 && ratio > 1.2){
        //         const cutWidth = img.height * 1.3;
        //         const cutStart = img.width - cutWidth;
        //         if(cutStart > 0){
        //             console.log("CUT START")
        //             position = (cutStart / 2) + 'px 0px'
        //         }else{
        //             console.log("CUT 0")
        //             if (img.width < 120 || img.height < 120) {
        //                 size = 'corver';
        //             }
        //         }
        //     }
        // }else{
        //     console.log(img.width * 0.8);
        size = (window.innerWidth * 0.34) + 'px auto'
        //size = '120px auto'
        // }
    } else {
        // size = 'auto 105px'
        size = 'auto 120px'

    }


    var retVal = {
        src: img.src,
        width: img.width,
        height: img.height,
        size: size,
        position: position,
    }


    return retVal;

}

export const getDemoData = async () => {

    const path = 'http://dev2-1028w.rozeus.com:15000/editorDemo'

    const resultList = await axios.get(path, null, null)
        .then((response) => {
            return response.data.msg[0].body;
        }).catch(err => {
            return null;
        });

    return resultList;
}


export const uploadImg = (file) => {
    // const path = 'http://106.247.124.178:21084/store/image/upload';
    const path = 'https://cdn.rozeus.com/store/image/upload';
    const data = new FormData();
    data.append('file',file)
    return axios({
        method:'post',
        headers: {
            'content-type': 'multipart/form-data'
        },
        url:path,
        data:data
    })

}
