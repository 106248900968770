export const FontFamily = {
    NotoSansKR: {
        fontFamily: "Noto Sans KR",
    },
    NanumGothic: {
        fontFamily: 'NanumGothic'
    },
    NanumMyeongjo: {
        fontFamily: 'NanumMyeongjo'
    },
    MalgunGothic: {
        fontFamily: 'MalgunGothic'
    },
    Batang: {
        fontFamily: 'Batang'
    },
    SpoqaHanSansNeo: {
        fontFamily: 'SpoqaHanSansNeo'
    },
    GyeonggiBatang: {
        fontFamily: 'GyeonggiBatang'
    },
    Pattaya: {
        fontFamily: 'Pattaya'
    },
    NanumPenScript: {
        fontFamily: 'NanumPenScript'
    },
    Teko: {
        fontFamily: 'Teko'
    }
};

export const FontSize = {
    SIZE_10PX: {fontSize: "10px"},
    SIZE_11PX: {fontSize: "11px"},
    SIZE_12PX: {fontSize: "12px"},
    SIZE_13PX: {fontSize: "13px"},
    SIZE_14PX: {fontSize: "14px"},
    SIZE_15PX: {fontSize: "15px"},
    SIZE_16PX: {fontSize: "16px"},
    SIZE_17PX: {fontSize: "17px"},
    SIZE_18PX: {fontSize: "18px"},
    SIZE_19PX: {fontSize: "19px"},
    SIZE_20PX: {fontSize: "20px"},
    SIZE_22PX: {fontSize: "22px"},
    SIZE_24PX: {fontSize: "24px"},
};

export const FontColor = {
    COLOR_WHITE: {
        color: "#ffffff",
    },
    COLOR_BLACK: {
        color: "#000000",
    },
    COLOR_RED: {
        color: "#ff0000",
    },
    COLOR_GREEN: {
        color: "#00ff00",
    },
    COLOR_BLUE: {
        color: "#0000ff",
    },
};

export const Background = {
    BACK_WHITE: {
        backgroundColor: "#ffffff",
    },
    BACK_BLACK: {
        backgroundColor: "#000000",
    },
    BACK_RED: {
        backgroundColor: "#ff0000",
    },
    BACK_GREEN: {
        backgroundColor: "#00ff00",
    },
    BACK_BLUE: {
        backgroundColor: "#0000ff",
    },
};

export const Subscript = {
    TEXT_SUP: {
        fontSize:'70%',
        verticalAlign:'super'
    },
    TEXT_SUB: {
        fontSize: '70%',
        verticalAlign: 'sub'
    },

};

export const LineHeight = {
    HEIGHT_200: {
        lineHeight: '200%',
    },
    HEIGHT_300: {
        lineHeight: '300%',
    },
};

export const ListTypeStyle = {
    UL_SQUARE : {
        listStyleType : 'square',
        // color:'red'
    },
    UL_CIRCLE: {
        listStyleType: 'circle',
        // color: 'blue'
    },
}