import { Popper } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { useRef, useState } from 'react';

import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';


import { DeleteAtomic, getAtomicPosition, MoveAtomic, MoveAtomic2, getAtomicOffsetTop } from './AtomicFunction';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { root_path } from '../../common/constants';

const VideoAtomic = ({editor, block, type, src}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const item = useRef();

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const moveScroll = () => {
        if(item != null){
            setTimeout(() => {
                const offsetTop = getAtomicOffsetTop(item);
                window.postMessage({ name: 'scrollDown', offsetTop: offsetTop - 100}, '*') //뉴스 아웃링크
            }, 0);
        }
    }
    const moveDownwardAtomic = () => {
        MoveAtomic(editor, block, 'after');
        moveScroll();
    }
    const moveUpwardAtomic = () => {
        MoveAtomic(editor, block, 'before');
        moveScroll();
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
                ref={item}
                onClick={handleClick}
                style={{ position:'relative', resize: 'both', overflow: 'auto', width:'100%' ,padding: '7px 0px', maxWidth: editor.isPC===true ? '420px' : null}}
            >
                <video id="background-video" preload={`auto`} controls style={{width:'100%'}}>
                    <source src={src} type="video/mp4" />
                </video>
                {!editor.readOnly &&
                    <Popper id={id} open={open} anchorEl={anchorEl} placement='top'
                        modifiers={{
                            flip: {enabled: false,},
                            preventOverflow: {enabled: false,},
                            offset: {
                              enabled: true,
                              offset: '0px 20px -50px -20px'
                            }
                        }}
                        style={{background:'white', borderRadius: '5px'}}
                    >
                        <div style={{ cursor: 'pointer' }}>
                            <ArrowUpwardIcon onClick={(e) => moveUpwardAtomic(block, editor)} style={{padding:'5px 5px 6px 5px', marginBottom:'-5px'}} />
                            <ArrowDownwardIcon onClick={(e) => moveDownwardAtomic(block, editor)} style={{padding:'5px 5px 6px 5px', marginBottom:'-5px'}} />
                            <DeleteForeverOutlinedIcon onClick={(e) => DeleteAtomic(block, editor)} style={{padding:'5px 5px 6px 5px', marginBottom:'-5px'}} />
                        </div>
                    </Popper>
                }

                {!editor.readOnly &&
                    <img src={root_path + '/images/transparentImg.png'} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} alt='___' />
                }
        </div>
        </div>
    )
}

export default inject(({ editor }) => ({
    editor: editor,
}))(observer(VideoAtomic));