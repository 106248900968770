import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { root_path } from '../../common/constants';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';


export const InsertVideo = () => {
    const [errorMsg , setErrorMsg] = useState('');
    const [open, setOpen] = useState(false);
    const [link, setLink] = useState('http://');
    const [snackOpen, setSnackOpen] = useState(false);

    const handleChange = (e) => {
        setLink(e.target.value);
    }
    const handleClickOpen = () => {
        setErrorMsg('');
        setLink('');
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    
    const handlePutLink = (e) => {
        const regexYoutube = /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)\&?/;
        const regexVideo = /^(?=(https?:\/\/)([^\/]*)(\.)(.*))((.*mp4.*))/;
        if(regexYoutube.exec(link)){
            window.postMessage({ type: 'SET', param: { type: 'video', action: link } }, '*');
            setOpen(false);
        }
        else if(regexVideo.exec(link)){
            window.postMessage({ type: 'SET', param: { type: 'video', action: link } }, '*');
            setOpen(false);
        }
        else{
            setErrorMsg('잘못된 URL 입니다.');
        }

        // if(regex.test(link)){
        //     window.postMessage({ type: 'SET', param: { type: 'video', action: link } }, '*');
        //     setOpen(false);
        // }
        // else{
        //     setSnackOpen(true);
        // }
        return "handled";
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handlePutLink(e);
        }
    }
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    }
    return (
        <React.Fragment>
        {
            // <LinkIcon style={{
            //     cursor: 'pointer',
            //     height: '30px',
            //     margin: '10px 0px',
            //     display: 'flex', justifyContent: 'center', alignItems: 'center',
            // }}
            // onClick={handleClickOpen}/>
        }
            <img
                className="item"
                src={root_path + '/icon/editor/outline-play_circle_filled_white-24px@2x.png'}
                alt=""
                onMouseUp={handleClickOpen}
                // onMouseDown={(e) => e.preventDefault()}
            />
            <Dialog fullScreen open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" style={{backgroundColor: 'black', opacity: '0.8', color: 'white'}}>동영상 URL 링크 첨부</DialogTitle>
                <DialogContent style={{backgroundColor: 'black', opacity: '0.8', color: 'white'}}>
                    <TextField
                        autoFocus
                        margin="dense"
                        // label="URL을 입력하세요."
                        type="text"
                        fullWidth
                        value={link}
                        onChange={(e) => handleChange(e)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        style={{color:'white'}}
                        placeholder="동영상 URL을 입력해주세요."
                    />
                    <span>{errorMsg}</span>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">취소</Button>
                    <Button onClick={handlePutLink} color="primary">확인</Button>
                </DialogActions>
                <Snackbar open={snackOpen} autoHideDuration={700} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }} onClose={handleSnackClose}>
                    <MuiAlert onClose={handleSnackClose} elevation={6} variant="filled" severity="error">
                        잘못된 주소 입니다.
                  </MuiAlert>
                </Snackbar>
            </Dialog>
        </React.Fragment>        
    )
}

export default InsertVideo;
