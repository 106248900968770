import { Popper } from "@material-ui/core";
import BackgroundImageOnLoad from "background-image-on-load";
import { inject, observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { getResizeInfo } from "../../common/interface/common";
import { DeleteAtomic, MoveAtomic, getAtomicOffsetTop } from "./AtomicFunction";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import styled from "styled-components";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const RepostStyled = styled.div`
  width: 100%;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
  height: ${(props) =>
    props.imgUrl && props.imgUrl !== null ? "88px" : "34px"};
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  overflow: hidden;
  img {
    display: block;
    width: 108px;
    height: 88px;
    height: auto;
  }
  p {
    font-family: "NS re";
    font-size: 14px;
    margin: 0;
    padding: 0px 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${(props) =>
      props.imgUrl && props.imgUrl !== null ? "2" : "1"};
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const NewsAtomic = ({ editor, block, type, item }) => {
  const [position, setPosition] = useState("center center");
  const [imgUrl, setImgUrl] = useState();
  const [imgLoadStatus, setImgLoadStatus] = useState(false);
  const news = useRef();

  const parseJson = (str) => {
    try {
      return JSON.parse(str);
    } catch {
      return str;
    }
  };

  useEffect(() => {
    var targetImg = item.img_urls;
    // console.log("item.img_urls", item);
    // console.log("item.img_urls", item.img_urls ? true : false);
    // console.log("item.img_urls", Array.isArray(item.img_urls));
    // console.log("item.img_urls", parseJson(item.img_urls)[0] !== null);

    // console.log("item.img_urls", parseJson(item.contents));
    if (item.img_urls) {
      if (Array.isArray(item.img_urls)) {
        if (parseJson(item.img_urls)[0] !== null) {
          targetImg =
            parseJson(item.img_urls) === item.img_urls
              ? item.img_urls
              : parseJson(item.img_urls)[0];
        } else {
            if(parseJson(item.contents).cont_1){
                if(parseJson(item.contents).cont_1.thumbnails && parseJson(item.contents).cont_1.thumbnails[0] !== null){
                    targetImg =   parseJson(item.contents).cont_1.thumbnails && parseJson(item.contents).cont_1.thumbnails.length > 0 ? parseJson(item.contents).cont_1.thumbnails[0] : parseJson(item.contents).cont_1.cont_imgs[0];
                }else{
                    targetImg = null;
                }
            }else{
                targetImg = null;
            }
          // targetImg = parseJson(item.contents) === item.contents ? null : parseJson(item.contents).cont_1.thumbnails && parseJson(item.contents).cont_1.thumbnails.length > 0 ? parseJson(item.contents).cont_1.thumbnails[0] : parseJson(item.contents).cont_1.cont_imgs[0]
        }
      } else {
        if (item.img_urls.indexOf("#") > 0) {
          targetImg = item.img_urls.substr(0, item.img_urls.indexOf("#"));
        }
      }
      //기존소스
      // if (item.img_urls.indexOf("#") > 0) {
      //     targetImg = item.img_urls.substr(0, item.img_urls.indexOf("#"))
      // }
      // else{
      //     targetImg = parseJson(item.img_urls) === item.img_urls ? item.img_urls : parseJson(item.img_urls)[0];
      // }
    } else {
      targetImg =
        parseJson(item.contents) === item.contents
          ? null
          : parseJson(item.contents).cont_1.thumbnails &&
            parseJson(item.contents).cont_1.thumbnails.length > 0
          ? parseJson(item.contents).cont_1.thumbnails[0]
          : parseJson(item.contents).cont_1.cont_imgs[0];
      //기존소스
      // targetImg = parseJson(item.contents) === item.contents ? null : parseJson(item.contents).cont_1.thumbnails.length > 0 ? parseJson(item.contents).cont_1.thumbnails[0] : parseJson(item.contents).cont_1.cont_imgs[0]
    }
    setImgUrl(targetImg);
  }, [item]);

  // const backgroundLoaded = () => {
  //     const img = getResizeInfo(targetImg, 0.34, 100);
  //     setImgUrl(targetImg);
  //     setPosition(img.position);
  //     setImgLoadStatus(true)
  // }

  // const backgroundLoadFail = () => {
  //     setImgUrl('/images/ico_news_default.png');
  //     setImgLoadStatus(false)
  // }
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    if (editor.readOnly) {
      //뉴스 클릭시 이벤트
      item.nid
        ? window.parent.postMessage(
            { name: "new_window", action: "outlink", type: "news", item: item },
            "*"
          ) //뉴스 아웃링크
        : window.parent.postMessage(
            {
              name: "new_window",
              action: "transform",
              type: "post",
              item: item,
            },
            "*"
          ); //포스트 아웃링크
    } else {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const moveScroll = () => {
    if (news != null) {
      setTimeout(() => {
        const offsetTop = getAtomicOffsetTop(news);
        window.postMessage(
          { name: "scrollDown", offsetTop: offsetTop - 100 },
          "*"
        ); //뉴스 아웃링크
      }, 0);
    }
  };
  const moveDownwardAtomic = () => {
    MoveAtomic(editor, block, "after");
    moveScroll();
  };
  const moveUpwardAtomic = () => {
    MoveAtomic(editor, block, "before");
    moveScroll();
  };

  const loadFail = () => {
    setImgUrl(null);
  };
  return (
    <React.Fragment>
      <div
        ref={news}
        style={{
          height: "88px",
          width: "calc(100%)",
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          padding: "0px",
          maxWidth: editor.isPC === true ? "420px" : null,
        }}
      >
        {
          // <BackgroundImageOnLoad
          //     src={targetImg}
          //     onLoadBg={backgroundLoaded}
          //     onError={backgroundLoadFail}
          // />
          // <RepostStyled style={{ cursor: 'pointer' }} onClick={handleClick}>
          //     {
          //         imgUrl &&
          //         <img src={imgUrl} alt="" />
          //     }
          //     <p>{item.title}</p>
          // </RepostStyled>
        }
        <RepostStyled
          style={{ cursor: "pointer" }}
          imgUrl={imgUrl}
          onClick={handleClick}
        >
          {imgUrl && imgUrl !== null && (
            <div
              style={{ width: "108px", height: "88px", position: "relative" }}
            >
              <img
                src={imgUrl}
                alt="pictures"
                style={{
                  width: "108px",
                  height: "88px",
                  display: "block",
                  filter: "blur(1px) brightness(0.5)",
                  position: "absolute",
                  border: "#e6e6e6",
                }}
              />
              <img
                src={imgUrl}
                alt="pictures"
                style={{
                  width: "108px",
                  height: "88px",
                  display: "block",
                  borderTopLeftRadius: "4px",
                  borderTopRightRadius: "4px",
                  objectFit: "contain",
                  position: "absolute",
                }}
                onError={loadFail}
              />
            </div>
          )}
          <div
            style={{
              width: "calc(100% - 108px)",
              display: "flex",
              alignItems: "center",
              height: "100%",
              padding: "0px",
            }}
          >
            <p>{item.title}</p>
          </div>
        </RepostStyled>
        {type === "news" ? (
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement="top"
            modifiers={{
              flip: { enabled: false },
              preventOverflow: { enabled: false },
              offset: {
                enabled: true,
                offset: "0px 20px -50px -20px",
              },
            }}
            style={{ background: "white", borderRadius: "5px" }}
          >
            <div style={{ cursor: "pointer" }}>
              <ArrowUpwardIcon
                onClick={(e) => moveUpwardAtomic(block, editor)}
                style={{ padding: "5px 5px 6px 5px", marginBottom: "-5px" }}
              />
              <ArrowDownwardIcon
                onClick={(e) => moveDownwardAtomic(block, editor)}
                style={{ padding: "5px 5px 6px 5px", marginBottom: "-5px" }}
              />
              <DeleteForeverOutlinedIcon
                onClick={(e) => DeleteAtomic(block, editor)}
                style={{ padding: "5px 5px 6px 5px", marginBottom: "-5px" }}
              />
            </div>
          </Popper>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default inject(({ editor }) => ({
  editor: editor,
}))(observer(NewsAtomic));
