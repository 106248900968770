import { observer, inject } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
// import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import EditDropdown from './EditDropdown';
import EditSubDropdown from './EditSubDropdown';
import menus from './menus';
import { root_path } from '../../../../common/constants';
import { uploadImg } from '../../../../common/interface/common';
import LeftFunctionIconBinder from './LeftFunctionIconBinder';

const EditBarStyled = styled.div`
    // bottom: 0;
    background-color: #ffffff;
    // position: fixed;
    display: flex;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid #e2e2e2;
    ul{
        padding-left: 0px;
        margin-bottom: 0px;
        margin-top: 0px;
    }
    .mainMenu,
    .subMenu {
        box-sizing: border-box;
        height: 48px;
        border-top: 1px solid #e2e2e2;
        img {
            display: block;
            height: 24px;
            width: 24px;
        }
        padding: 0px 10px;
    }
    .mainMenu {
        display: flex;
        width: 73.5px;
        border-right: 1px solid #e2e2e2;
        justify-content: space-around;
        align-items: center;
        padding: 12px 16px;
    }
    .subMenu {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left,
        .right {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            .item {
                padding: 0px 4px;
                // max-width: 65px;
            }
        }
        .right {
            margin-left: 8px;
        }
    }
    .text,
    .TOPIC {
        font-family: 'NS bo';
        letter-spacing: -0.26px;
        font-size: 13px;
        z-index: 3;
        display: flex;
        align-items: center;
    }
    .text {height: 100%;}
    .NotoSans {font-family: 'NotoSans';}
    .NanumGothic {font-family: 'NanumGothic';}
    .NanumMyeongjo {font-family: 'NanumMyeongjo';}
    .MalgunGothic {font-family: 'MalgunGothic';}
    .Batang {font-family: 'Batang';}
    .SpoqaHanSansNeo {font-family: 'SpoqaHanSansNeo';}
    .GyeonggiBatang {font-family: 'GyeonggiBatang';}
    .Pattaya {font-family: 'Pattaya';}
    .NanumPenScript {font-family: 'NanumPenScript';}
    .Teko {font-family: 'Teko';}
`;
const Overlay = styled.div`
    position: ${({ show }) => (show ? 'fixed' : 'none')};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 2;
`;

const LiStyled = styled.li`
    z-index: 3;
`;

const Li = ({ icon, onClick, ...rest }) => (
    <LiStyled onClick={onClick} {...rest}>
        <img src={icon} alt="" />
    </LiStyled>
);
const menuArr = [
    {id: 0, icon: root_path + '/icon/editor/ic_editor_font.png',},
    {id: 1, icon: root_path + '/icon/editor/twotone-closed_caption-24px.png',},
    {id: 2, icon: null, text: 'TOPIC'},
    {id: 3, icon: root_path + '/icon/editor/ic_editor_bulleted.png',},
    {id: 4, icon: root_path + '/icon/editor/ic_editor_camera.png',},
];

export const defaultFont = 'NotoSansKR';
export const defaultFontSize = 16;

const EditBar = ({ editorRef, editor }) => {
    const [show, setShow] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [isShow2, setIsShow2] = useState(false);
    const [fontsize, setFontSize] = useState(null);
    const [fontText , setFontStyle] = useState(null);
    const [menuNum, setMenuNum] = useState(0);
    const [clientHeight, setClientHeight] = useState(null);
    const fontRef = useRef(null);
    const ref = useRef(null);
    const fullHeight = document.body.clientHeight;
    /* 아래의 빈훅이 없으면 툴바 클릭 이후 포커스가 이상함..  */
    useEffect(() => {
        if(fontsize === null){
            editor.setFontSize(defaultFontSize);
            editor.setFontStyle(defaultFont);
        }

        window.addEventListener('message', (e) => {
            if(e.data.name === 'test'){}
        });
    }, []);
    useEffect(() => {}, [editor.editorState.getSelection().focusOffset]);
    /* 위의 빈훅이 없으면 툴바 클릭 이후 포커스가 이상함..  */

    useEffect(() => {
        // editor.setFontStyle(null);
        if(fontText != null){
            fontRef.current.className = `text ${fontText}`;//state update 액션없이 active된 폰트값 표시를 위한   
            onAction({ type: 'font', action: fontText });
        }
    }, [fontText]);
    
    useEffect(() => {
        editor.setFontSize(null);
        if(fontsize != null){
            onAction({ type: 'font', action: `SIZE`, size: `SIZE_${fontsize}PX`});
        }
    }, [fontsize]);

    const setHandleFontSize = () => {
        if(fontsize != null){
            fontsize >= 20 ? setFontSize(10) : setFontSize(fontsize + 1);
        }
        else{
            setFontSize(defaultFontSize + 1);
        }
        editor.setFontSize(fontsize);
    };

    const setHandleFontStyle = (e) => {
        setFontStyle(e.target.className);
        editor.setFontStyle(e.target.innerText);
    };
    
    const onShow = () => {
        setIsShow(!isShow);
    };
    
    const onShow2 = (e) => {
        
        if(!isShow2){
            if(editor.fontStyle != null){
                editor.setFontStyle(editor.fontStyle);
                setFontStyle(null);
            }
            
            setTimeout(() => {
                if(fullHeight !== document.body.clientHeight){
                    setClientHeight(document.body.clientHeight);
                }
            }, 500);
        }
        setIsShow2(!isShow2);
    };

    const onClick = (id) => () => {
        setMenuNum(id);
    };

    const onAction = (param) => {
        window.postMessage({ type: 'SET', param }, '*');
    };

    const imageUpload = (e) => {//이미지 업로드할때 형식이 틀린파일이 포함되면, 나머지 파일 업로드 완료 후 alert 처리
        if(e.target.files[0]){
            window.parent.postMessage({ name: 'editor', action: 'overlayLoading' ,isLoading: true }, '*');
            const cnt = e.target.files.length;
            let fail = false;
            Array.from(e.target.files).map((ev, i) => {
                uploadImg(ev).then(function (response) {
                    const p1 = new Promise((resolve, reject) => {
                        try {
                            if (response) {
                                if (response.status === 200) {
                                    if (response.data.image_urls.length > 0) {
                                        onAction({ type: 'image', action: response.data.image_urls[0] });
                                        resolve({idx: i, isFail: fail});
                                    }
                                }
                            }
                        }
                        catch {
                            fail = true;
                            resolve({idx: i, isFail: fail});
                        }
                    });
                    p1.then((item) => {
                        if (cnt - 1 === item.idx) {//마지막 카운트일때 한번만 실행
                            if(item.isFail){//alert 호출 후 기존 loading false
                                window.parent.postMessage({ name: 'editor', action: 'alert', text: '이미지형식이 아닌 파일이 있습니다.' }, '*');
                                setTimeout(() => {
                                    window.parent.postMessage({ name: 'editor', action: 'overlayLoading', isLoading: false }, '*');
                                }, 100);
                            }
                            else{
                                window.parent.postMessage({ name: 'editor', action: 'overlayLoading', isLoading: false }, '*');
                            }
                        }
                    });
                });
            });
        }
        else {
            window.parent.postMessage({ name: 'editor', action: 'overlayLoading', isLoading: false }, '*');
        }
    }
    
    return (
        <EditBarStyled
            // style={
            //     !editor.isApp ? {
            //         position: 'relative', 
            //         borderTop: 'none',
            //         borderBottom: '1px solid #e2e2e2',
            //         zIndex: '1'
            //     }:null
            // } 
            ref={ref}>
            <EditDropdown show={isShow} onShow={onShow}>
                <div className="mainMenu">
                    {menuArr[menuNum].icon ? (
                        <img src={menuArr[menuNum].icon} alt="" />
                    ) : (
                        <div className="text item">{menuArr[menuNum].text}</div>
                    )}
                    <img src={root_path + "/icon/editor/up_arrow.png"} alt="" onClick={() => setShow(!show)} />
                </div>
                <ul>
                    {menuArr.map((e,i) => {
                        if (e.id === menuNum) return null;
                        return e.icon ? (
                            <Li className="item" icon={e.icon} onClick={onClick(e.id)} key={i}/>
                        ) : (
                            <li className="TOPIC item" onClick={onClick(e.id)} key={i}>
                                {e.text}
                            </li>
                        );
                    })}
                </ul>
                <Overlay show={isShow} onClick={onShow} />
            </EditDropdown>
            <div id="toolbar" className="subMenu">
                <div className="left">
                    {menus[menuNum].left.map((e, i) => {
                        if (e.font) {
                            return (
                                <div style={{height:'100%'}} key={i}>
                                    <EditSubDropdown fontBoxHeight={clientHeight} className="item" show={isShow2} onShow={onShow2} onMouseDown={(e) => e.preventDefault()}>
                                        <span ref={fontRef} className={`text ${editor.fontStyle != null ? editor.fontStyle : fontText}`} >
                                            {editor.fontStyle != null ? editor.fontStyle : fontText}
                                        </span>
                                        <ul style={{zIndex:'3'}}>
                                            <li className="Teko" onClick={setHandleFontStyle}>
                                                Teko
                                            </li>
                                            <li className="NanumGothic" onClick={setHandleFontStyle}>
                                                나눔고딕
                                            </li>
                                            <li className="NanumMyeongjo" onClick={setHandleFontStyle}>
                                                나눔명조
                                            </li>
                                            <li className="MalgunGothic" onClick={setHandleFontStyle}>
                                                맑은고딕
                                            </li>
                                            <li className="Batang" onClick={setHandleFontStyle}>
                                                바탕체
                                            </li>
                                            <li className="SpoqaHanSansNeo" onClick={setHandleFontStyle}>
                                                SpoqaHanSansNeo
                                            </li>
                                            <li className="GyeonggiBatang" onClick={setHandleFontStyle}>
                                                경기청년바탕
                                            </li>
                                            <li className="Pattaya" onClick={setHandleFontStyle}>
                                                Pattaya
                                            </li>
                                            <li className="NanumPenScript" onClick={setHandleFontStyle}>
                                                NanumPenScript
                                            </li>
                                            <li className="NotoSansKR" onClick={setHandleFontStyle}>
                                                NotoSansKR
                                            </li>
                                        </ul>
                                        {
                                            <Overlay show={isShow2} onClick={onShow2} />
                                        }
                                    </EditSubDropdown>
                                </div>
                            );
                        } else {
                            return <LeftFunctionIconBinder
                                e={e}
                                setHandleFontSize={setHandleFontSize}
                                viewFontSize={editor.fontSize}
                                fontsize={fontsize == null ? defaultFontSize : fontsize}
                                onAction={onAction}
                                key={i}
                            />
                        }
                    })}
                </div>
                <div className="right">
                    {menus[menuNum].right.map((e, i) =>
                        e.fontsize ? (
                            <span className="text item" onClick={setHandleFontSize} key={i}>
                                {fontsize}
                            </span>
                        ) : (
                            <div key={i}>
                                <img className="item" src={e.icon} alt="" onClick={() => e.onClick(onAction,editor)} />
                            </div>
                        )
                    )}
                    <input style={{display:'none'}} type='file' accept="image/*" id='imageUploader' onMouseDown={(e) => e.preventDefault()} onChange={imageUpload} multiple/>
                </div>
            </div>
        </EditBarStyled>
    );
};


export default inject(({ editor, news }) => ({
    editor: editor,
    news: news
}))(observer(EditBar));
