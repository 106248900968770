import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { insertNewUnstyledBlock} from 'draftjs-utils';
import { getOG } from './../../common/interface/common';
import { root_path } from '../../common/constants';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

export const InsertOpenGraph = ({editor}) => {
    const [errorMsg , setErrorMsg] = useState('');
    const [open, setOpen] = useState(false);
    const [link, setLink] = useState('http://');
    const [snackOpen, setSnackOpen] = useState(false);
    const handleChange = (e) => {
        setLink(e.target.value);
    }
    const handleClickOpen = () => {
        setErrorMsg('');
        setLink('');
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    
    const create = () => {
        var url = link;
        var check = false;
        if (url.indexOf('www') > -1) {
            const count = (url.match(/\./g) || []).length;
            if (count > 1) {
                check = true;
            }
        } else {
            check = true;
        }
        if (check) {
            getOG(url).then(function (response) {
                if (response) {
                    window.postMessage({ type: 'SET', param: { type: 'openGraph', action: {
                        ogUrl : response.ogUrl,
                        ogTitle : response.ogTitle,
                        ogDescription : response.ogDescription,
                        ogImage : response.ogImage.url
                    }}}, '*');
                }
                else{
                    window.postMessage({ type: 'SET', param: { type: 'openGraph', action: {
                        ogUrl : url,
                        ogTitle : '유효하지 않은 링크 입니다.',
                        ogDescription : '유효하지 않은 링크 입니다.',
                        ogImage : '/icon/editor/bg_og_error.png'
                    }}}, '*');
                }
            })
        }
    }

    const handlePutLink = (e) => {
        const regex = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm);
        if(regex.test(link)){
            editor.setEditorState(insertNewUnstyledBlock(editor.editorState));
            // window.postMessage({ type: 'SET', param: { type: 'openGraph', action: link } }, '*');
            create();
            setOpen(false);
        }
        else{
            // setSnackOpen(true);
            setErrorMsg('잘못된 URL 입니다.');
        }
        return "handled";
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handlePutLink(e);
        }
    }

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    }
    return (
        <React.Fragment>
        {
            // <LinkIcon style={{
            //     cursor: 'pointer',
            //     height: '30px',
            //     margin: '10px 0px',
            //     display: 'flex', justifyContent: 'center', alignItems: 'center',
            // }}
            // onClick={handleClickOpen}/>
        }
            <img
                className="item"
                src= {root_path + '/icon/editor/ic_editor_link@2x.png'}
                alt=""
                onMouseUp={handleClickOpen}
                // onMouseDown={(e) => e.preventDefault()}
            />
            <Dialog fullScreen open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" style={{backgroundColor: 'black', opacity: '0.8', color: 'white'}}>오픈 그래프 URL 링크 첨부</DialogTitle>
                <DialogContent style={{backgroundColor: 'black', opacity: '0.8', color: 'white'}}>
                    <TextField
                        autoFocus
                        margin="dense"
                        // label="URL을 입력하세요."
                        type="text"
                        fullWidth
                        value={link}
                        onChange={(e) => handleChange(e)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        style={{color:'white'}}
                        placeholder="생성할 오픈 그래프 URL을 입력해주세요."
                    />
                    <span>{errorMsg}</span>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">취소</Button>
                    <Button onClick={handlePutLink} color="primary">확인</Button>
                </DialogActions>
                <Snackbar open={snackOpen} autoHideDuration={700} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }} onClose={handleSnackClose}>
                    <MuiAlert onClose={handleSnackClose} elevation={6} variant="filled" severity="error">
                        잘못된 주소 입니다.
                  </MuiAlert>
                </Snackbar>
            </Dialog>
        </React.Fragment>        
    )
}

export default InsertOpenGraph;

