import { EditorState, Modifier, RichUtils } from "draft-js"
import _ from 'lodash'
import { fontFamily, fontSize } from "../../common/constants";
export const fontChange = (setFunc, state, style) => {
    const selection = state.getSelection();
    if(selection.focusOffset === selection.anchorOffset && selection.focusKey === selection.anchorKey){
        var addStyle = JSON.parse(localStorage.getItem('add-inline-style'));//이번 액션에 추가된 style
        var removeStyle = JSON.parse(localStorage.getItem('del-inline-style'));//이번 액션에 제거된 style
        addStyle = addStyle==null?[]:addStyle; //초기화
        removeStyle = removeStyle==null?[]:removeStyle; //초기화
        var currentStyle = [...state.getCurrentInlineStyle()].filter(v=> !removeStyle.includes(v));//현재 스타일
        var isAppend = [...currentStyle, ...addStyle].includes(style)?false:true;
        if(isAppend){
            localStorage.setItem('add-inline-style',JSON.stringify(_.uniq([...addStyle, style])));
            localStorage.setItem('del-inline-style',JSON.stringify(_.without([...removeStyle],style)));
        }
        else{
            localStorage.setItem('add-inline-style',JSON.stringify(_.without([...addStyle],style)));
            localStorage.setItem('del-inline-style',JSON.stringify(_.uniq([...removeStyle, style])));
        }
        var result = _.uniq(JSON.parse(localStorage.getItem('add-inline-style')).concat([...state.getCurrentInlineStyle()]).filter(v=> !JSON.parse(localStorage.getItem('del-inline-style')).includes(v)));
        if(style != null){//fontSize 제거 후 마지막 선택한 스타일 적용
            if(style.indexOf("SIZE_") >= 0){
                result = result.filter(e=>{
                    if(e != null){
                        return e.indexOf("SIZE_")>=0? null : e
                    }
                });
                result.push(style);
            }
        }
        var isFont = fontFamily.filter(e=>e.key === style);
        if(isFont.length>0){
            var fonts = fontFamily.map(e=>e.key);
            result = _.difference(result,fonts).concat(style);
        }


        if(state.getSelection().focusOffset === state.getSelection().anchorOffset){//일반 입력시
            localStorage.setItem('key-inline-style', state.getSelection().focusKey);
            if(localStorage.getItem('idx-inline-style') == null){
                localStorage.setItem('idx-inline-style', state.getSelection().focusOffset);
            }
            localStorage.setItem('inline-style',JSON.stringify(result));
        }
        else{//드래그 블록잡고 스타일 추가시 추가하고 storage 초기화
            localStorage.removeItem('idx-inline-style');
            localStorage.removeItem('inline-style');
            localStorage.removeItem('add-inline-style');
            localStorage.removeItem('del-inline-style');
        }
    }
    else{
        const resetData = (object) => {
            const nextContentState = object.reduce((contentState, val) => {
                return Modifier.removeInlineStyle(contentState, selection, val.key)
            }, state.getCurrentContent());
            let nextEditorState = EditorState.push(
                state,
                nextContentState,
                'change-inline-style'
            );
            const currentStyle = state.getCurrentInlineStyle();
            // if (selection.isCollapsed()) {
            //     nextEditorState = currentStyle.reduce((state, color) => {
            //         console.log(color)
            //         return RichUtils.toggleInlineStyle(state, color);
            //     }, nextEditorState);
            // }
            if (!currentStyle.has(style)) {
                nextEditorState = RichUtils.toggleInlineStyle(
                    nextEditorState,
                    style
                );
            }
            setFunc(nextEditorState);
        }
        if(_.find(fontFamily,{key : style}) != null){
            resetData(fontFamily);
        }
        else if(style.indexOf('SIZE_')>=0){
            resetData(fontSize);
        }
        else {
            setFunc(RichUtils.toggleInlineStyle(state, style));

        }
            
    }
}

export const blockTypeChange = (setFunc, state, style) => {
    setFunc(RichUtils.toggleBlockType(state, style));
}

export const linkTypeChange = (setFunc, state, url) => {
    const contentWithEntity = state.getCurrentContent().createEntity("link", "MUTABLE", {src: url});
    const newEditorState = EditorState.set(state, { currentContent: contentWithEntity });
    setFunc(RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        contentWithEntity.getLastCreatedEntityKey()
    ));
}

export const leafTypeChange = (setFunc, state, url) => {
    const contentWithEntity = state.getCurrentContent().createEntity("leaf", "MUTABLE", {src: null});
    const newEditorState = EditorState.set(state, { currentContent: contentWithEntity });
    setFunc(RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        contentWithEntity.getLastCreatedEntityKey()
    ));
}
