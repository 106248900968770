// export const server_addr = 'http://106.247.124.178:30212/rozeus/api/v1';
// export const news_server_addr = 'http://106.247.124.178/service/api/v1';
// export const server_addr = 'http://106.247.124.178:30212/rozeus/api/v1';
// export const news_server_addr = 'http://api.rozeus.com/service/api/v1';
// export const root_path = '/rozeus_editor';
// export const server_addr = 'https://api.rozeus.com';
// export const news_server_addr = 'https://api.rozeus.com/service/api/v1';
export const server_addr = process.env.REACT_APP_API_SERVER_URL;
export const news_server_addr = process.env.REACT_APP_API_SERVER_URL + '/service/api/v1';
export const root_path = '';

export const fontFamily = [
    {
        key:'NotoSansKR',
        viewText:'NotoSansKR',
    },
    {
        key: 'NanumGothic',
        viewText: '나눔고딕',
    },
    {
        key: 'NanumMyeongjo',
        viewText: '나눔명조',
    },
    {
        key: 'MalgunGothic',
        viewText: '맑은고딕',
    },
    {
        key: 'Batang',
        viewText: '바탕체',
    },
    {
        key: 'SpoqaHanSansNeo',
        viewText: 'SpoqaHanSansNeo',
    },
    {
        key: 'GyeonggiBatang',
        viewText: '경기청년바탕',
    },
    {
        key: 'Pattaya',
        viewText: 'Pattaya',
    },
    {
        key: 'NanumPenScript',
        viewText: 'NanumPenScript',
    },
    {
        key: 'Teko',
        viewText: 'Teko',
    },
]

export const fontSize = [
    {
        key: 'SIZE_10PX',
        viewText: '10px',
    },
    {
        key: 'SIZE_11PX',
        viewText: '11px',
    },
    {
        key: 'SIZE_12PX',
        viewText: '12px',
    },
    {
        key: 'SIZE_13PX',
        viewText: '13px',
    },
    {
        key: 'SIZE_14PX',
        viewText: '14px',
    },
    {
        key: 'SIZE_15PX',
        viewText: '15px',
    },
    {
        key: 'SIZE_16PX',
        viewText: '16px',
    },
    {
        key: 'SIZE_17PX',
        viewText: '17px',
    },
    {
        key: 'SIZE_18PX',
        viewText: '18px',
    },
    {
        key: 'SIZE_19PX',
        viewText: '19px',
    },
    {
        key: 'SIZE_20PX',
        viewText: '20px',
    },
]

export const lineHeight = [
    {
        key: '',
        viewText: 'Normal',
    },
    {
        key: 'HEIGHT_200',
        viewText: '200%',
    },
    {
        key: 'HEIGHT_300',
        viewText: '300%',
    },

]

export const blockType = [
    {
        key: '',
        viewText: 'Normal',
    },
    {
        key: 'header-one',
        viewText: 'H1',
    },
    {
        key: 'header-two',
        viewText: 'H2',
    },
    {
        key: 'header-three',
        viewText: 'H3',
    },
    {
        key: 'header-four',
        viewText: 'H4',
    },
    {
        key: 'header-five',
        viewText: 'H5',
    },
    {
        key: 'header-six',
        viewText: 'H6',
    },
    {
        key: 'unordered-list-item',
        viewText: 'UL',
    },
    {
        key: 'ordered-list-item',
        viewText: 'OL',
    },
    {
        key: 'blockquote',
        viewText: 'T',
    },{
        key: 'UL_SQUARE',
        viewText: 'SQUARE',
    },
]